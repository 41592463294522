<template>
  <BModal
    :modal-name="modalName"
    :delegate="delegate"
    width="454px"
    height="auto"
  >
    <BHeightAdjuster>
      <SfLinkBox
        :sf-related-link-info="sfRelatedLinkInfo"
        :sf-exec-sync-btn="sfExecSyncBtn"
        @is-loading="updateLoading"
        @click:modal-close="close"
      />
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import SfLinkBox from '@/components/organisms/user/general/modal/salesforce/SfStatusModal/SfLinkBox.vue';
import { KEY_OF_MODAL } from '@/injection-keys';

export type TBmodalDelegate = {
  shouldClose?: () => boolean | Promise<boolean>;
  beforeClose?: () => void;
};
type TProps = {
  modalName: string;
  sfRelatedLinkInfo: {
    sfSyncedAt: Date,
    sfRelatedLinks: [{
      status: string,
      title: string,
      url: string,
      errorMessage: string,
    }],
  },
  sfExecSyncBtn: {
    type: boolean,
    default: true,
  }
};
const props = withDefaults(defineProps<TProps>(), {});
const delegate = {
  shouldClose: () => {
    return !isLoading.value;
  },
};
const isLoading = ref(false);
const updateLoading = (result) => {
  isLoading.value = result;
};

const modal = inject(KEY_OF_MODAL);
const close = () => {
  modal.hide('sfRelatedLinkModalDetail');
};
</script>
