import { ref, watch } from 'vue';
import { DirectMailActionDetail, DirectMailSequenceStepMaster, NextActionActionDetail, NextActionSequenceStepMaster, SequenceBody, SequenceStepBody, SequenceStepInstance, SequenceStepMaster, SequenceStepInstanceAllOfStatusEnum } from '@/api/openapi';
import { makeNextActionType } from '@/api/user/models/next_action_types';
import { TFormDirectMailSequenceStep, TFormNextActionSequenceStep, TFormSequenceStep, TStepType, TWithActionTypePartialSequenceStepMaster } from '@/components/organisms/user/general/sequence/types';

type TStep = TFormSequenceStep | SequenceStepMaster;
type TNextActionStep = TFormNextActionSequenceStep | NextActionSequenceStepMaster;
type TDirectMailSequenceStep = TFormDirectMailSequenceStep | DirectMailSequenceStepMaster;

const DIRECT_MAIL_RESERVE_ACTION_TYPE_ID = 'default3';
const DIRECT_MAIL_MANUAL_ACTION_TYPE_ID = 'default4';
const DIRECT_MAIL_ACTION_TYPE_IDS = [DIRECT_MAIL_RESERVE_ACTION_TYPE_ID, DIRECT_MAIL_MANUAL_ACTION_TYPE_ID];

const getDefaultActionTypeId = (stepType: TStepType): string | null =>
  stepType === 'direct_mail' ? DIRECT_MAIL_RESERVE_ACTION_TYPE_ID : null;
const getStepTypeByActionType = (actionType: string): TStepType =>
  DIRECT_MAIL_ACTION_TYPE_IDS.includes(actionType) ? 'direct_mail' : 'next_action';

const isNextActionSequenceStep = (step: TStep): step is TNextActionStep =>
  'nextActionContent' in step;

const isDirectMailSequenceStepType = (step: TStep): step is TDirectMailSequenceStep =>
  'content' in step
  && 'subject' in step
  && 'sendCc' in step
  && 'sendBcc' in step
  && 'open' in step
  && 'click' in step;

const isDirectMailActionType = (actionType: string): boolean =>
  getStepTypeByActionType(actionType) === 'direct_mail';

const isDirectMailSequenceStep = (step: TFormSequenceStep): step is TFormDirectMailSequenceStep =>
  isDirectMailActionType(step.actionType) && isDirectMailSequenceStepType(step);

const isDirectMailStepExecuted = (step: TFormSequenceStep): boolean =>
  isDirectMailActionType(step.actionType) && isExecutedStep(step);

const isDirectMailStepExecutedForSequenceStepInstance = (step: SequenceStepInstance): boolean => {
  const actionType = makeNextActionType(step.defaultActionTypeId, step.actionTypeId);
  return isDirectMailActionType(actionType) && isExecutedStep(step) && !isFailedStep(step.status);
};

const isFailedStep = (status: SequenceStepInstanceAllOfStatusEnum | null): boolean =>
  status === SequenceStepInstanceAllOfStatusEnum.Failed;

const isExecutedStep = (step: TFormSequenceStep): boolean =>
  step.reservedAt != null && new Date(step.reservedAt) <= new Date() && step.mailStatus !== 'draft';

const getActionDetail = (step: TStep): NextActionActionDetail | DirectMailActionDetail =>
  isNextActionSequenceStep(step)
    ? {
      nextActionContent: step.nextActionContent,
    }
    : {
      subject: step.subject,
      content: step.content,
      sendCc: step.sendCc,
      sendBcc: step.sendBcc,
      open: step.open,
      click: step.click,
      mailAttachmentIds: step.mailAttachmentIds || [],
    };

const getDisplayContent = (step: TFormSequenceStep): string => {
  if (isDirectMailSequenceStep(step)) {
    return step.subject;
  }
  return step.nextActionContent;
};

const getDisplaySubContent = (step: TFormSequenceStep): string => {
  if (isDirectMailSequenceStep(step)) {
    return step.content;
  }
  return null;
};

const isDirectMailReserved = (step: TFormSequenceStep | TWithActionTypePartialSequenceStepMaster): boolean =>
  step.actionType === DIRECT_MAIL_RESERVE_ACTION_TYPE_ID;
const isAutomatic = (step: TFormSequenceStep | TWithActionTypePartialSequenceStepMaster): boolean =>
  isDirectMailReserved(step);

const usePostPutBody = () => {
  const sequenceBodyData = ref<SequenceBody>({
    name: '',
    ownerId: null,
  });
  const sequenceStepsBodyData = ref<SequenceStepBody[]>([]);
  const canSave = ref(false);

  const handleUpdateSequence = (sequenceBody: SequenceBody) => {
    sequenceBodyData.value = sequenceBody;
  };
  // NOTE: TFormSequenceStepからpost用の型に詰め替える
  const handleUpdateSequenceSteps = (sequenceSteps: TFormSequenceStep[]) => {
    sequenceStepsBodyData.value = sequenceSteps.map((step) => {
      const { id, sortOrder, actionType, dateInterval, reservedTime, priority } = step;
      // NOTE: 推論が上手くいかないのでasで型を指定
      return {
        id,
        sortOrder,
        actionType,
        dateInterval,
        reservedTime,
        actionDetail: getActionDetail(step),
        priority,
      } as SequenceStepBody;
    });
  };

  watch(() => sequenceBodyData.value.name, (newValue) => {
    canSave.value = !!newValue;
  });

  return {
    sequenceBodyData,
    sequenceStepsBodyData,
    canSave,
    handleUpdateSequence,
    handleUpdateSequenceSteps,
  };
};

export {
  DIRECT_MAIL_RESERVE_ACTION_TYPE_ID,
  DIRECT_MAIL_MANUAL_ACTION_TYPE_ID,
  usePostPutBody,
  getStepTypeByActionType,
  getDefaultActionTypeId,
  getActionDetail,
  getDisplayContent,
  getDisplaySubContent,
  isDirectMailReserved,
  isAutomatic,
  isDirectMailActionType,
  isDirectMailStepExecuted,
  isDirectMailStepExecutedForSequenceStepInstance,
};
