<template>
  <FilterDetailPopover
    v-model:is-include="isInclude"
    :title="title"
    is-switch
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="popoverHide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout
        align-center
      >
        <BListItem>
          <template #header>
            <BLayout
              justify-space-between
            >
              <span>{{ title }}</span>
              <div
                class="grouping-text"
                :class="{ 'is-include': incExc === 'inc' }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterItemCheckbox
        v-model:checked-item="checkedItem"
        :candidate-items="candidateItems"
        :blank-item="blankItem"
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash';
import { mapGetters } from 'vuex';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue';
import FilterItemCheckbox from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue';
import { getIncExc } from '@/utils/object';

export default {
  components: {
    FilterDetailPopover,
    FilterItemCheckbox,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      require: true,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      checkedItem: [],
      isInclude: true,
    };
  },
  computed: {
    ...mapGetters('user', [
      'assignedUserCandidates',
    ]),
    candidateItems () {
      return this.assignedUserCandidates.map((user) => ({ label: user.name, value: user.id }));
    },
    blankItem () {
      return { label: this.$t('operatorAssign.unassigned'), value: -1 };
    },
    title () {
      return this.$t(`filterSelect.leadAdditionalInfo.item.${this.keyName}`);
    },
    value () {
      if (this.target == null || this.targetDetail.length === 0) return '-';
      const names = this.targetDetail.map((o) => {
        const operator = this.getOperator(o);
        return operator == null ? this.$t('general.delete.done') : operator.name;
      });
      return names.join(' / ');
    },
    groupingText () {
      if (this.target == null || this.targetDetail.length === 0) return;
      return this.$t(`general.filter.${this.incExc}`);
    },
    target () {
      return this.leadListFilterParams[this.keyName];
    },
    targetDetail () {
      if (this.target == null) return [];
      return this.target[this.incExc].or.map(v => parseInt(v));
    },
    incExc () {
      return getIncExc(this.target);
    },
  },
  methods: {
    popoverShow () {
      this.checkedItem = lodash.cloneDeep(this.targetDetail);
      this.isInclude = this.incExc === 'inc';
    },
    popoverHide () {
      const grouping = this.isInclude ? 'inc' : 'exc';
      this.$emit('hide', { [grouping]: { or: this.checkedItem } });
    },
    getOperator (operatorId) {
      if (parseInt(operatorId) === -1) return { id: operatorId, name: this.$t('operatorAssign.unassigned') };
      return this.assignedUserCandidates.find(operator => {
        return operator.id === parseInt(operatorId);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;
    &.is-include {
      color: $basecolor-hot;
    }
  }
</style>
