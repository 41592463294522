export const types = {
  SET_SELECTED_FREE_TAG_INFO: 'SET_SELECTED_FREE_TAG_INFO',
  IS_UPDATE_CALL_LIST: 'IS_UPDATE_CALL_LIST',
  SET_SELECTED_CALL_RESULT: 'SET_SELECTED_CALL_RESULT',
  SET_SELECTED_OPPORTUNITY: 'SET_SELECTED_OPPORTUNITY',
  SET_SELECTED_NEXT_ACTION: 'SET_SELECTED_NEXT_ACTION',
  SET_SELECTED_SEQUENCE_INSTANCE_ID: 'SET_SELECTED_SEQUENCE_INSTANCE_ID',
  SET_ZOOM_PHONE_WIDGET_VISIBLE: 'SET_ZOOM_PHONE_WIDGET_VISIBLE',
  SET_MIITEL_WIDGET_VISIBLE: 'SET_MIITEL_WIDGET_VISIBLE',
  SET_TARGET_CALL_RESULT_FORM_VISIBLE: 'SET_TARGET_CALL_RESULT_FORM_VISIBLE',
  SET_TARGET_OPPORTUNITY_FORM_VISIBLE: 'SET_TARGET_OPPORTUNITY_FORM_VISIBLE',
  SET_TARGET_FREE_ACTION_FORM_VISIBLE: 'SET_TARGET_FREE_ACTION_FORM_VISIBLE',
  SET_TARGET_SEQUENCE_INSTANCE_FORM_VISIBLE: 'SET_TARGET_SEQUENCE_INSTANCE_FORM_VISIBLE',
  SET_TARGET_SINGLE_NEXT_ACTION_FORM_VISIBLE: 'SET_TARGET_SINGLE_NEXT_ACTION_FORM_VISIBLE',
  SET_TARGET_SEQUENCE_STEP_FORM_VISIBLE: 'SET_TARGET_SEQUENCE_STEP_FORM_VISIBLE',
  SET_TARGET_MAIL_FORM_VISIBLE: 'SET_TARGET_MAIL_FORM_VISIBLE',
  SET_TARGET_MENU_VISIBLE_RESET: 'SET_TARGET_MENU_VISIBLE_RESET',
  SET_TARGET_HEADER_MOVE_BUTTON_DISABLED: 'SET_TARGET_HEADER_MOVE_BUTTON_DISABLED',
  SET_LEAD_LIST_FILTER_PARAMS: 'SET_LEAD_LIST_FILTER_PARAMS',
  SET_BEFORE_LEAD_LIST_FILTER_PARAMS: 'SET_BEFORE_LEAD_LIST_FILTER_PARAMS',
  SET_LEAD_LIST_PAGE_PARAMS: 'SET_LEAD_LIST_PAGE_PARAMS',
  SET_IS_LEAD_OPEN_BY_LEAD_LIST: 'SET_IS_LEAD_OPEN_BY_LEAD_LIST',
  SET_LEAD_LIST_SELECTED_COLUMNS: 'SET_LEAD_LIST_SELECTED_COLUMNS',
  SET_LEAD_LIST_SORTABLE_COLUMNS: 'SET_LEAD_LIST_SORTABLE_COLUMNS',
  SET_LEAD_LIST_COLUMN_WIDTHS: 'SET_LEAD_LIST_COLUMN_WIDTHS',
  SET_FILTER_CONTROL_PANEL_VISIBLE: 'SET_FILTER_CONTROL_PANEL_VISIBLE',
  SET_SELECTED_FILTER_DETAIL_KEY_NAME: 'SET_SELECTED_FILTER_DETAIL_KEY_NAME',
  SET_LEAD_LIST_SORT_KEY: 'SET_LEAD_LIST_SORT_KEY',
  SET_LEAD_LIST_SORT_ORDER: 'SET_LEAD_LIST_SORT_ORDER',
  SET_PENDING_CSV_IMPORT_TASK_IDS: 'SET_PENDING_CSV_IMPORT_TASK_IDS',
  ADD_PENDING_CSV_IMPORT_TASK_ID: 'ADD_PENDING_CSV_IMPORT_TASK_ID',
  SET_PENDING_CSV_OVERWRITE_TASK_IDS: 'SET_PENDING_CSV_OVERWRITE_TASK_IDS',
  ADD_PENDING_CSV_OVERWRITE_TASK_ID: 'ADD_PENDING_CSV_OVERWRITE_TASK_ID',
  SET_PENDING_EXCEL_IMPORT_TASK_IDS: 'SET_PENDING_EXCEL_IMPORT_TASK_IDS',
  ADD_PENDING_EXCEL_IMPORT_TASK_ID: 'ADD_PENDING_EXCEL_IMPORT_TASK_ID',
  SET_PENDING_EXCEL_OVERWRITE_TASK_IDS: 'SET_PENDING_EXCEL_OVERWRITE_TASK_IDS',
  ADD_PENDING_EXCEL_OVERWRITE_TASK_ID: 'ADD_PENDING_EXCEL_OVERWRITE_TASK_ID',
  CLEAR_LEAD_LIST_FILTER_PARAMS: 'CLEAR_LEAD_LIST_FILTER_PARAMS',
  SET_PENDING_SF_IMPORT_TASK_IDS: 'SET_PENDING_SF_IMPORT_TASK_IDS',
  ADD_PENDING_SF_IMPORT_TASK_ID: 'ADD_PENDING_SF_IMPORT_TASK_ID',
  SET_DO_RELOAD_LEAD_MERGE_STATUS: 'SET_DO_RELOAD_LEAD_MERGE_STATUS',
  SET_CURRENT_CALL_TARGET_ID: 'SET_CURRENT_CALL_TARGET_ID',
  SET_IS_DISABLED_B_ICON_WITH_ACTION_MENU_CLOSE: 'SET_IS_DISABLED_B_ICON_WITH_ACTION_MENU_CLOSE',
};

export const mutations = {
  [types.SET_SELECTED_FREE_TAG_INFO](state, selectedFreeTagInfo) {
    state.selectedFreeTagInfo = selectedFreeTagInfo;
  },
  [types.IS_UPDATE_CALL_LIST](state, isUpdateCallList) {
    state.isUpdateCallList = isUpdateCallList;
  },
  [types.SET_SELECTED_CALL_RESULT](state, selectedCallResult) {
    state.selectedCallResult = selectedCallResult;
  },
  [types.SET_SELECTED_OPPORTUNITY](state, selectedOpportunity) {
    state.selectedOpportunity = selectedOpportunity;
  },
  [types.SET_SELECTED_NEXT_ACTION](state, selectedNextAction) {
    state.selectedNextAction = selectedNextAction;
  },
  [types.SET_SELECTED_SEQUENCE_INSTANCE_ID](state, selectedSequenceInstanceId) {
    state.selectedSequenceInstanceId = selectedSequenceInstanceId;
  },
  [types.SET_ZOOM_PHONE_WIDGET_VISIBLE](state, zoomPhoneWidgetVisible) {
    state.zoomPhoneWidgetVisible = zoomPhoneWidgetVisible;
  },
  [types.SET_MIITEL_WIDGET_VISIBLE](state, miitelWidgetVisible) {
    state.miitelWidgetVisible = miitelWidgetVisible;
  },
  [types.SET_TARGET_CALL_RESULT_FORM_VISIBLE](state, targetCallResultFormVisible) {
    state.targetCallResultFormVisible = targetCallResultFormVisible;
  },
  [types.SET_TARGET_OPPORTUNITY_FORM_VISIBLE](state, targetOpportunityFormVisible) {
    state.targetOpportunityFormVisible = targetOpportunityFormVisible;
  },
  [types.SET_TARGET_FREE_ACTION_FORM_VISIBLE](state, targetFreeActionFromVisible) {
    state.targetFreeActionFromVisible = targetFreeActionFromVisible;
  },
  [types.SET_TARGET_SEQUENCE_INSTANCE_FORM_VISIBLE](state, targetSequenceInstanceFormVisible) {
    state.targetSequenceInstanceFormVisible = targetSequenceInstanceFormVisible;
  },
  [types.SET_TARGET_SINGLE_NEXT_ACTION_FORM_VISIBLE](state, targetSingleNextActionFormVisible) {
    state.targetSingleNextActionFormVisible = targetSingleNextActionFormVisible;
  },
  [types.SET_TARGET_SEQUENCE_STEP_FORM_VISIBLE](state, targetSequenceStepFormVisible) {
    state.targetSequenceStepFormVisible = targetSequenceStepFormVisible;
  },
  [types.SET_TARGET_MAIL_FORM_VISIBLE](state, targetMailFormVisible) {
    state.targetMailFormVisible = targetMailFormVisible;
  },
  [types.SET_TARGET_MENU_VISIBLE_RESET](state) {
    state.targetCallResultFormVisible = false;
    state.targetOpportunityFormVisible = false;
    state.targetFreeActionFromVisible = false;
    state.targetSequenceInstanceFormVisible = false;
    state.targetSingleNextActionFormVisible = false;
    state.targetMailFormVisible = false;
  },
  [types.SET_TARGET_HEADER_MOVE_BUTTON_DISABLED](state, targetHeaderMoveButtonDisabled) {
    state.targetHeaderMoveButtonDisabled = targetHeaderMoveButtonDisabled;
  },
  [types.SET_LEAD_LIST_FILTER_PARAMS](state, leadListFilterParams) {
    state.leadListFilterParams = leadListFilterParams;
  },
  [types.SET_LEAD_LIST_PAGE_PARAMS](state, leadListPageParams) {
    state.leadListPageParams = leadListPageParams;
  },
  [types.SET_IS_LEAD_OPEN_BY_LEAD_LIST](state, isLeadOpenByLeadList) {
    state.isLeadOpenByLeadList = isLeadOpenByLeadList;
  },
  [types.SET_LEAD_LIST_SELECTED_COLUMNS](state, leadListSelectedColumns) {
    state.leadListSelectedColumns = leadListSelectedColumns;
  },
  [types.SET_LEAD_LIST_SORTABLE_COLUMNS](state, leadListSortableColumns) {
    state.leadListSortableColumns = leadListSortableColumns;
  },
  [types.SET_LEAD_LIST_COLUMN_WIDTHS](state, leadListColumnWidths) {
    state.leadListColumnWidths = leadListColumnWidths;
  },
  [types.SET_FILTER_CONTROL_PANEL_VISIBLE](state, filterControlPanelVisible) {
    state.filterControlPanelVisible = filterControlPanelVisible;
  },
  [types.SET_SELECTED_FILTER_DETAIL_KEY_NAME](state, selectedFilterDetailKeyName) {
    state.selectedFilterDetailKeyName = selectedFilterDetailKeyName;
  },
  [types.SET_LEAD_LIST_SORT_KEY](state, leadListSortKey) {
    state.leadListSortKey = leadListSortKey;
  },
  [types.SET_LEAD_LIST_SORT_ORDER](state, leadListSortOrder) {
    state.leadListSortOrder = leadListSortOrder;
  },
  [types.SET_PENDING_CSV_IMPORT_TASK_IDS](state, pendingTaskIds) {
    state.pendingCsvImportTaskIds = pendingTaskIds;
  },
  [types.ADD_PENDING_CSV_IMPORT_TASK_ID](state, taskId) {
    state.pendingCsvImportTaskIds.push(taskId);
  },
  [types.SET_PENDING_CSV_OVERWRITE_TASK_IDS](state, pendingTaskIds) {
    state.pendingCsvOverwriteTaskIds = pendingTaskIds;
  },
  [types.ADD_PENDING_CSV_OVERWRITE_TASK_ID](state, taskId) {
    state.pendingCsvOverwriteTaskIds.push(taskId);
  },
  [types.SET_PENDING_EXCEL_IMPORT_TASK_IDS](state, pendingTaskIds) {
    state.pendingExcelImportTaskIds = pendingTaskIds;
  },
  [types.ADD_PENDING_EXCEL_IMPORT_TASK_ID](state, taskId) {
    state.pendingExcelImportTaskIds.push(taskId);
  },
  [types.SET_PENDING_EXCEL_OVERWRITE_TASK_IDS](state, pendingTaskIds) {
    state.pendingExcelOverwriteTaskIds = pendingTaskIds;
  },
  [types.ADD_PENDING_EXCEL_OVERWRITE_TASK_ID](state, taskId) {
    state.pendingExcelOverwriteTaskIds.push(taskId);
  },
  [types.CLEAR_LEAD_LIST_FILTER_PARAMS](state) {
    state.leadListFilterParams = {};
  },
  [types.SET_PENDING_SF_IMPORT_TASK_IDS](state, pendingTaskIds) {
    state.pendingSfImportTaskIds = pendingTaskIds;
  },
  [types.ADD_PENDING_SF_IMPORT_TASK_ID](state, taskId) {
    state.pendingSfImportTaskIds.push(taskId);
  },
  [types.SET_DO_RELOAD_LEAD_MERGE_STATUS](state, doReloadLeadMergeStatus) {
    state.doReloadLeadMergeStatus = doReloadLeadMergeStatus;
  },
  [types.SET_CURRENT_CALL_TARGET_ID](state, data) {
    state.currentCallTargetId = data;
  },
  [types.SET_IS_DISABLED_B_ICON_WITH_ACTION_MENU_CLOSE](state, isDisabledBIconWithActionMenuClose) {
    state.isDisabledBIconWithActionMenuClose = isDisabledBIconWithActionMenuClose;
  },
};
