<template>
  <div class="b-editable-content-textarea">
    <template v-if="editing">
      <BTextarea
        v-bind="textareaProps"
        ref="textarea"
        v-model="internalValue"
        class="textarea"
        @blur="handleCheck"
      />
    </template>
    <template v-else>
      <div
        class="content"
        :class="{'editing': editing}"
        @click="handleEdit"
      >
        {{ formattedValue }}
      </div>
    </template>
  </div>
</template>

<script>
import { altText } from '@/utils/alt';

export default {
  props: {
    placeholder: String,
    modelValue: {
      type: String,
      default: null,
    },
    required: Boolean,
  },
  emits: [
    'update:modelValue',
    'click',
    'cancel',
    'check',
  ],
  data () {
    return {
      originalValue: this.modelValue,
      editing: false,
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.modelValue;
      },
      set (newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal);
      },
    },
    textareaProps () {
      return {
        placeholder: this.placeholder,
        rows: 2,
        autosize: true,
      };
    },
    rules () {
      return {
        content: [
          { required: true, message: 'Please input Activity name', trigger: 'blur' },
        ],
      };
    },
    formattedValue () {
      return altText(this.modelValue);
    },
  },
  methods: {
    handleEdit (e) {
      this.editing = true;
      this.$emit('click', e);
      this.setFocus();
    },
    handleCheck (e) {
      if (this.internalValue === this.originalValue) {
        this.$emit('cancel', e);
        this.editing = false;
        return false;
      } else {
        this.$emit('check', this.internalValue);
        this.originalValue = this.internalValue;
        this.editing = false;
      }
    },
    setFocus () {
      this.$nextTick(() => this.$refs.textarea.setFocus());
    },
  },
};
</script>

<style lang="scss" scoped>
  .content {
    width: 100%;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all 300ms $standard-easing;
    padding: $basespace-50 $basespace-100;
    white-space: pre-wrap;
    word-wrap: break-word;

    &:not(.editing) {
      &:hover {
        border: 1px solid $bdcolor-base;
        cursor: pointer;
      }
    }
  }
</style>
