<template>
  <BLayout align-center>
    <div class="mr-400">
      <BUserTooltip
        :image-url="(nextAction.owner || {}).avatarUrl"
        :name="(nextAction.owner || {}).name"
        :user-id="(nextAction.owner || {}).id"
        :size="40"
      />
    </div>
    <BBtn
      class="mr-400"
      size="mini"
      fab
      @click.stop="handleDone"
    >
      <BIcon size="mini">
        check
      </BIcon>
    </BBtn>
    <BCustomIcon
      v-if="isSequenceAction"
      icon-class="b-sequence"
      class="pr-100"
      size="medium"
    />
    <div class="nextaction-title">
      <span class="nextaction-type">
        {{ nextAction.name }}
      </span>
      <BPriorityPreview
        :priority="nextAction.priority"
        size="micro"
        class="mt-50"
      />
    </div>
    <div
      v-if="!isSequenceAction"
      class="nextaction-content"
      @click.stop="handleClickEditor"
    >
      <BEditableContentTextarea
        v-model="content"
        :placeholder="$t('nextAction.content')"
        @check="handleUpdateContent"
      />
    </div>
    <div
      v-else
      class="nextaction-content"
    >
      <div
        class="card-item nextaction-comment-area readonly"
      >
        {{ content }}
      </div>
    </div>
    <div class="nextaction-support">
      <div class="nextaction-reserved-at">
        {{ reservedAtWithDateAndTimeFilter }}
      </div>
      <div class="nextaction-action-area">
        <span>{{ $t('general.edit.do') }}</span>
        <BIcon size="small">
          chevron_right
        </BIcon>
      </div>
    </div>
    <div
      class="px-100"
      @click.stop=""
    >
      <BMoreMenu
        v-if="!isSequenceAction"
        attach
      >
        <BBtn
          flat
          fit
          size="small"
          @click="handleDelete"
        >
          {{ $t(`general.delete.do`) }}
        </BBtn>
      </BMoreMenu>
    </div>
  </BLayout>
</template>

<script>
import { useNextActionComputed } from '@/composable/user/nextAction/next-action';
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  props: {
    nextAction: Object,
  },
  emits: [
    'click-done',
    'click-edit',
    'click-delete',
    'update',
    'click-editor',
  ],
  setup(props) {
    const { isSequenceAction } = useNextActionComputed(props.nextAction);
    return {
      isSequenceAction,
    };
  },
  data() {
    return {
      content: this.nextAction.content,
    };
  },
  computed: {
    reservedAtWithDateAndTimeFilter() {
      return altText(formatShorterDateTime(this.nextAction?.reservedAt));
    },
  },
  watch: {
    'nextAction.content'(newVal) {
      this.content = newVal;
    },
  },
  methods: {
    handleDone() {
      this.$emit('click-done', this.nextAction);
    },
    handleEdit() {
      this.$emit('click-edit', this.nextAction);
    },
    handleDelete() {
      this.$emit('click-delete', this.nextAction);
    },
    handleUpdateContent() {
      this.$emit('update', {
        ...this.nextAction,
        content: this.content,
      });
    },
    handleClickEditor() {
      this.$emit('click-editor');
    },
  },
};
</script>

<style lang="scss" scoped>
  .nextaction-title {
    @include m-fixed-width(240px);
    .nextaction-type {
      font-weight: 600;
    }
    .priority-preview {
      color: $textcolor-light;
    }
  }

  .nextaction-content {
    width: 100%;
  }
</style>
