<template>
  <MailFormTransition
    :is-visible="internalIsOpen"
    style="height: 100%"
  >
    <FormDrawer
      v-if="currentState === 'form'"
      :model-value="internalSequenceStep"
      :users="activeUsers"
      :is-loading="loading"
      :is-available-mail-template-feature="isAvailableMailTemplateFeature"
      :sender-data="senderData"
      :send-to-address="props.sendToAddress"
      :draftable="draftable"
      @update:model-value="handleUpdateModelValue"
      @change-current-state-to-confirm="handleChangeToConfirm"
      @cancel="handleCancel"
      @draft="draft"
      @close-openai="handleCloseOpenAi"
    />
    <ConfirmDrawer
      v-else-if="currentState === 'confirm'"
      :editable="mode === 'form'"
      :step="internalSequenceStep"
      :is-loading="loading"
      :sender-data="senderData"
      :submit-type="submitType"
      @edit="edit"
      @save-as-draft="saveAsDraft"
      @save-as-reserve="saveAsReserve"
      @reserve="reserve"
      @send-immediately="sendImmediately"
      @close="handleClose"
    />
  </MailFormTransition>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import MailFormTransition from '@/components/organisms/user/mail/common/drawer/MailFormTransition.vue';
import { getCommonDefault } from '@/components/organisms/user/mail/direct/drawer/state/form-values';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { TSubmitType } from '@/composable/user/sequence/step-actions';
import { useStepModal } from '@/composable/user/sequence/step-form-modal';
import { useUsers, useCurrentUser } from '@/composable/user/user/users';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TPartialFormDirectMailCommon, SenderData } from '../../mail/direct/drawer/state/types';
import ConfirmDrawer from './directMailForm/ConfirmDrawer.vue';
import FormDrawer from './directMailForm/FormDrawer.vue';
import { TFormDirectMailSequenceStep } from './types';

export type TState = 'form' | 'confirm';
type TProps = {
  sequenceStep: TPartialFormDirectMailCommon | null;
  isOpen?: boolean;
  mode: TState;
  senderId?: number;
  loading?: boolean;
  canConfirm?: (sequenceStep: TFormDirectMailSequenceStep) => Promise<boolean>;
  submitType?: TSubmitType;
  withSender?: boolean;
  sendToAddress?: string | null;
};
type TEmit = {
  'click:saveAsDraft': [sequenceStep: TFormDirectMailSequenceStep];
  'click:saveAsReserve': [sequenceStep: TFormDirectMailSequenceStep];
  'click:reserve': [sequenceStep: TFormDirectMailSequenceStep];
  'click:sendImmediately': [sequenceStep: TFormDirectMailSequenceStep];
  'click:draft': [sequenceStep: TFormDirectMailSequenceStep];
  'click:confirm': [sequenceStep: TFormDirectMailSequenceStep];
  'click:cancel': [];
};

const props = withDefaults(defineProps<TProps>(), {
  isOpen: false,
  loading: false,
  canConfirm: undefined,
  submitType: 'save',
  senderId: undefined,
  withSender: true,
  sendToAddress: null,
});
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const { isAvailableMailTemplateFeature } = useAvailableFeatureCheck();

const { activeUsers, fetchUsers } = useUsers();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const sequenceOwner = computed(() => activeUsers.value.find(u => u.id === props.senderId));
const draftable = computed(() => props.submitType === 'send');

const currentState = ref<TState>(props.mode);
const cancelOpenai = ref<() => void>();
const senderData = ref<SenderData>(null);

onBeforeMount(async () => {
  await fetchCurrentUser();
  await fetchUsers();
  setSenderData();
});

const {
  internalIsOpen,
  internalSequenceStep,
} = useStepModal<TFormDirectMailSequenceStep>(
  () => props.isOpen,
  () => ({
    sortOrder: 1,
    actionType: null,
    priority: 'middle',
    dateInterval: 1,
    reservedTime: null,
    ...getCommonDefault(),
    mailAggregates: (props.sequenceStep as TFormDirectMailSequenceStep)?.mailAggregates || null,
    ...props.sequenceStep,
  }),
);

watch(() => props.mode, (value) => {
  currentState.value = value;
});

watch(() => props.isOpen, (value) => {
  if (value) return;
  closeModal();
});

const toConfirm = () => {
  emit('click:confirm', internalSequenceStep.value);
  currentState.value = 'confirm';
};
const toForm = () => {
  currentState.value = 'form';
};
const reset = () => {
  currentState.value = props.mode;
};

const closeModal = () => {
  reset();
  emit('click:cancel');
};

const handleUpdateModelValue = (value: TFormDirectMailSequenceStep) => {
  internalSequenceStep.value = value;
};
const handleChangeToConfirm = async () => {
  if (props.canConfirm != null && !await props.canConfirm(internalSequenceStep.value)) return;

  toConfirm();
};

const handleCancel = async () => {
  const check = await bitterAlert.show({
    title: i18n.t('general.confirm.text'),
    text: i18n.t('general.alert.of', { target: i18n.t('general.unsavedValue'), action: i18n.t('general.clear') }),
  });
  if (!check) { return; }
  if (cancelOpenai.value != null) { cancelOpenai.value(); }
  closeModal();
};
const handleClose = () => {
  if (props.mode === 'confirm') {
    closeModal();
  } else {
    handleCancel();
  }
};

const edit = () => {
  toForm();
};
const saveAsDraft = () => {
  emit('click:saveAsDraft', internalSequenceStep.value);
};
const saveAsReserve = () => {
  emit('click:saveAsReserve', internalSequenceStep.value);
};
const reserve = () => {
  emit('click:reserve', internalSequenceStep.value);
};
const sendImmediately = () => {
  emit('click:sendImmediately', internalSequenceStep.value);
};
const draft = () => {
  if (!draftable.value) return;
  emit('click:draft', internalSequenceStep.value);
};

const handleCloseOpenAi = (cancel: () => void) => {
  cancelOpenai.value = cancel;
};

const setSenderData = () => {
  if (!props.withSender) return;
  const user = sequenceOwner.value || currentUser.value;
  if (user == null) return { senderName: '', senderEmail: '' };

  senderData.value = {
    senderName: `${user.lastName} ${user.firstName}`,
    senderEmail: user.senderEmail,
  };
};
</script>

<style lang="scss" scoped>
.confirm-container {
  height: 100%;
}
</style>
