import Waiting from '@/store/waiting';

export default class UnsubscribeActions {
  constructor (Api) {
    const actions = {
      async unsubscribeAction ({ commit }, args) {
        await waiting.check('unsubscribeAction', async () => {
          try {
            await Api.unsubscribe(args);
          } catch (err) {
            throw err;
          }
        });
      },
      async checkUnsubscribeAction ({ commit }, args) {
        return waiting.check('checkUnsubscribeAction', async () => {
          try {
            const res = await Api.checkUnsubscribe(args);
            return res;
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
