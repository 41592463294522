import { DefaultNextActionType, NextActionType } from '@/api/openapi';

type NextActionTypeOrDefaultType = {
  name: string;
  id: string;
};

/**
 * defaultActionTypeId, actionTypeIdからactionTypeに加工する
 */
const makeNextActionType = (
  defaultActionTypeId: number | null,
  actionTypeId: number | null,
): string => 
  defaultActionTypeId ? makeActionTypeBytDefaultActionTypeId(defaultActionTypeId) : actionTypeId.toString();

const makeActionTypeBytDefaultActionTypeId = (defaultActionTypeId: number): string => `default${defaultActionTypeId}`;

const mergeNextActionTypesAndDefaultTypes = (nextActionTypes: NextActionType[], defaultNextActionTypes: DefaultNextActionType[]): NextActionTypeOrDefaultType[] =>
  defaultNextActionTypes
    .map(e => {
      return {
        name: e.name,
        id: makeActionTypeBytDefaultActionTypeId(e.id),
      };
    }).concat(nextActionTypes.map(e => {
      return {
        name: e.name,
        id: `${e.id}`,
      };
    }));

/**
 * nextActionType, defaultNextActionTypeを取り扱うサービス
 */
class NextActionTypesAndDefaultTypes {
  nextActionTypes: NextActionType[];
  defaultNextActionTypes: DefaultNextActionType[];

  constructor(nextActionTypes: NextActionType[], defaultNextActionTypes: DefaultNextActionType[]) {
    this.nextActionTypes = nextActionTypes;
    this.defaultNextActionTypes = defaultNextActionTypes;
  }

  getSelectableNextActionTypesAndDefaultTypes(): NextActionTypeOrDefaultType[] {
    return mergeNextActionTypesAndDefaultTypes(this.nextActionTypes, this.defaultNextActionTypes.filter(e => !e.isNotSelectableByUser));
  }

  getMergedNextActionTypesAndDefaultTypes(): NextActionTypeOrDefaultType[] {
    // TODO: 個別メールを除いている。個別メール実装時戻す
    // return mergeNextActionTypesAndDefaultTypes(this.nextActionTypes, this.defaultNextActionTypes);
    return this.getSelectableNextActionTypesAndDefaultTypes();
  }
}

export type {
  NextActionTypeOrDefaultType,
};
export {
  makeNextActionType,
  NextActionTypesAndDefaultTypes,
};
