import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { AvailableFeatures } from '@/api/user/resources/available_feature';
import { key } from '@/store/index';

const useAvailableFeatureCheck = () => {
  const store = useStore(key);
  const availableFeatures = ref<AvailableFeatures>();
  /**
   * Vuex user.salesforceSetting が読み込まれている必要あり
   */
  const salesforceSetting = computed(() => store.getters['user/salesforceSetting']);
  /**
   * Vuex user.client が読み込まれている必要あり
   */
  const client = computed(() => store.getters['user/client']);

  const isAvailableFeature = (key: keyof AvailableFeatures['feature']) => {
    return !!availableFeatures.value?.feature?.[key];
  };

  const fetchAvailableFeaturesAction = async () : Promise<void> => {
    await store.dispatch('user/getAvailableFeaturesAction');
    availableFeatures.value = store.getters['user/availableFeatures'];
  };
  const promiseOfFetchAvailableFeatures = fetchAvailableFeaturesAction();

  const isSlugContained = (slugs: string[]) => slugs.includes(client.value?.slug || '');


  const isAvailableBulkMailFeature = computed<boolean>(() => isAvailableFeature('bulk_mail'));
  const isAvailableDirectMailFeature = computed<boolean>(() => isAvailableFeature('direct_mail'));
  const isAvailableIntercomFeature = computed<boolean>(() => isAvailableFeature('intercom'));
  const isAvailableSalesforceLinkFeature = computed<boolean>(() => isAvailableFeature('salesforce_link'));
  const isAvailableAnalyticsLinkFeature = computed<boolean>(() => isAvailableFeature('analytics'));
  const isAvailableMailTemplateFeature = computed<boolean>(() => isAvailableFeature('mail_template'));
  const isAvailableSalesforceApiFeature = computed<boolean>(() => isAvailableSalesforceLinkFeature.value && salesforceSetting.value?.connectStatus == 'connected');
  const isAvailableAiFeatureToBeReleased = computed<boolean>(() => false);
  const isAvailableBetaPage = computed<boolean>(() => availableFeatures.value?.betaFeature && Object.values(availableFeatures.value.betaFeature).includes(true));
  const isAvailableSequenceFeature = computed<boolean>(() => isAvailableFeature('sequence'));
  const isAvailableTriggerFeature = computed<boolean>(() => isSlugContained(['debug-1', 'test1', 'balescloud-001', 'demo-005', 'recruit001']));
  const isAvailableMfaFeature = computed<boolean>(() => isSlugContained(['debug-1', 'test1', 'sc-debug', 'demo-005', 'nttprint']));
  /**
   * TODO: 直近の導入のための一時的な処置で使用。不要になり次第消す
   */
  const isJinjer = computed<boolean>(() => client.value?.slug === 'jinjer');

  return {
    promiseOfFetchAvailableFeatures,
    isAvailableBulkMailFeature,
    isAvailableDirectMailFeature,
    isAvailableIntercomFeature,
    isAvailableSalesforceLinkFeature,
    isAvailableAnalyticsLinkFeature,
    isAvailableMailTemplateFeature,
    isAvailableSalesforceApiFeature,
    isAvailableBetaPage,
    isAvailableSequenceFeature,
    isAvailableTriggerFeature,
    isAvailableMfaFeature,
    isAvailableAiFeatureToBeReleased,
    // TODO: 直近の導入のための一時的な処置で使用。不要になり次第消す
    isJinjer,
  };
};

export {
  useAvailableFeatureCheck,
};
