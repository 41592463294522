<template>
  <BDrawerMenu
    v-model="isOpen"
    class="trigger-event-form-container"
    width="600px"
    :title="$t('trigger.eventSetting.title')"
    @cancel="handleCancelClick"
  >
    <div
      class="trigger-event-form-body-container"
    >
      <BListItem class="pb-100">
        <span class="fs-300 fw-bold">{{ $t('trigger.eventSetting.leadSelect') }}</span>
      </BListItem>
      <BListItem class="list-item">
        <ElDescriptions
          class="margin-top"
          :column="1"
          border
        >
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('trigger.eventSetting.leadFilterSelect.title') }}</span>
            </template>
            <span>{{ $t(`trigger.eventSetting.leadFilterSelect.${selectedLeadSelectType}`) }}</span>
          </ElDescriptionsItem>
          <ElDescriptionsItem
            v-if="selectedLeadSelectType === 'leadList'"
          >
            <template #label>
              <span>{{ $t('trigger.eventSetting.leadListSelect') }}</span>
            </template>
            <LeadViewSelectPanel
              v-model="internalTriggerEvent.leadViewId"
              class="lead-view-select-panel"
              full-width
              list-height="60vh"
              use-model
              is-display-only
            />
          </ElDescriptionsItem>
          <ElDescriptionsItem
            v-if="selectedLeadSelectType === 'leadCustom'"
          >
            <template #label>
              <span>{{ $t('trigger.eventSetting.leadCustomSelect') }}</span>
            </template>
            <FilterControlPanelBody
              :lead-list-filter-params="leadListFilterParams"
              :filter-category-visible="false"
              is-display-only
            />
          </ElDescriptionsItem>
        </ElDescriptions>
      </BListItem>
      <BListItem class="pt-200 pb-200">
        <span class="fs-300 fw-bold">{{ $t('trigger.eventSetting.eventSelect') }}</span>
      </BListItem>
      <div class="list-group">
        <BListItem class="list-item">
          <template #header>
            <span>{{ $t('trigger.eventSetting.eventType') }}</span>
          </template>
          <span>{{ i18n.t(`trigger.eventTypes.${internalTriggerEvent.eventType}`) }}</span>
        </BListItem>
      </div>
      <div class="list-group">
        <BListItem class="list-item">
          <template #header>
            <div class="mb-200">
              {{ $t('trigger.eventSetting.eventDetail') }}
            </div>
          </template>
        </BListItem>
        <div
          v-if="!!internalTriggerEvent.eventType"
          v-loading="loading"
          class="event-detail-container confirm"
        >
          <template
            v-for="form in forms"
            :key="form.eventDetailKey"
          >
            <template v-if="form.inputType === 'sequence_select'">
              <BListItem class="list-item">
                <template #header>
                  <span>{{ form.label }}</span>
                </template>
                <SequenceMasterSimpleSelect
                  v-model="(internalTriggerEvent.eventDetail[form.eventDetailKey] as number | null)"
                  :is-display-only="true"
                />
              </BListItem>
            </template>
            <template v-if="form.inputType === 'mail_type_sequence_step_select'">
              <BListItem class="list-item">
                <template #header>
                  <span>{{ form.label }}</span>
                </template>
                <SequenceStepMasterSimpleSelect
                  v-model="(internalTriggerEvent.eventDetail[form.eventDetailKey] as number | null)"
                  :sequence-master-id="(internalTriggerEvent.eventDetail[form.eventDetailBelongsToKey] as number | null)"
                  :condition="{ stepType: 'direct_mail' }"
                  :is-display-only="true"
                />
              </BListItem>
            </template>
            <template v-if="form.inputType === 'multi_text_input'">
              <BListItem class="list-item">
                <template #header>
                  <span>{{ form.label }}</span>
                </template>
                <div
                  v-for="(url, index) in internalTriggerEvent.eventDetail[form.eventDetailKey]"
                  :key="index"
                >
                  <div class="mb-100">
                    {{ url }}
                  </div>
                </div>
              </BListItem>
            </template>
          </template>
        </div>
      </div>
    </div>
    <template #footer>
      <div
        class="footer"
      >
        <BLayout
          align-center
          justify-center
        >
          <BBtn
            class="mr-300 btn"
            text
            @click="handleCancelClick"
          >
            {{ $t('general.cancel.text') }}
          </BBtn>
          <BBtn
            class="ml-300 btn"
            type="primary"
            outline
            :disabled="!editable"
            @click="handleEditClick"
          >
            {{ $t('general.edit.text') }}
          </BBtn>
        </BLayout>
      </div>
    </template>
  </BDrawerMenu>
</template>

<script lang="ts" setup>
import camelcaseKeys from 'camelcase-keys';
import { ref, watch, nextTick, computed, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { TriggerEventBody } from '@/api/openapi';
import FilterControlPanelBody from '@/components/organisms/user/leadList/filter/FilterControlPanelBody.vue';
import LeadViewSelectPanel from '@/components/organisms/user/LeadViewSelectPanel.vue';
import { useTriggerEvent, useTriggerEventForm } from '@/composable/user/trigger/trigger-event';
import { key } from '@/store/index';
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue';
import SequenceStepMasterSimpleSelect from '../SequenceStepMasterSimpleSelect.vue';

defineProps<TProps>();

const emit = defineEmits<TEmits>();

type TProps = {
  editable: boolean;
};
type TEmits = {
  'click:cancel': [];
  'click:triggerEvent': [triggerEvent: TriggerEventBody, menu: string];
};
const model = defineModel<TriggerEventBody | null>();
const isOpen = defineModel<boolean>('isOpen', { default: false });

const i18n = useI18n();
const store = useStore(key);

const { makeInitialTriggerEvent } = useTriggerEvent();
const { forms, fetchForms, loading } = useTriggerEventForm();

const selectedLeadSelectType = ref<string>('leadList');
const internalTriggerEvent = ref<TriggerEventBody>();

const clearInternalValues = () => {
  internalTriggerEvent.value = makeInitialTriggerEvent(model.value);
  forms.value = [];
};
clearInternalValues();

const leadListFilterParams = computed(() => store.getters['userUi/leadListFilterParams']);

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
    storeLeadListFilterParams();
  });
});

watch(() => leadListFilterParams.value, value => {
  internalTriggerEvent.value.filterParams = value;
});

watch(() => internalTriggerEvent.value.eventType, value => {
  if (value) fetchForms(value);
});

watch(() => internalTriggerEvent.value.leadViewId, value => {
  if (value) selectedLeadSelectType.value = 'leadList';
});

watch(() => internalTriggerEvent.value.filterParams, value => {
  if (value && Object.keys(value).length > 0) selectedLeadSelectType.value = 'leadCustom';
});

const storeLeadListFilterParams = () => {
  const value = internalTriggerEvent.value.filterParams;
  if (value && Object.keys(value).length > 0) {
    store.dispatch('userUi/setLeadListFilterParamsAction',
      camelcaseKeys(value, {
        deep: true,
        exclude: [/^custom_field_.*[^items]/g],
      }),
    );
  }
};

onUnmounted(() => {
  store.dispatch('userUi/clearLeadListFilterParamsAction');
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleEditClick = () => {
  emit('click:triggerEvent', model.value, 'edit');
};
</script>
