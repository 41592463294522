<template>
  <div class="call-target-menu">
    <BLayout class="call-target-menu-layout">
      <div
        v-if="activeCti === 'miitel'"
        class="miitel-contents"
      >
        <MiitelWidget
          v-if="recreateState"
          ref="miitelWidget"
          :company-id="miitelCompanyId"
          :access-key="miitelAccessKey"
        />
      </div>
      <ZoomPhoneWidget
        v-if="activeCti === 'zoom'"
      />
      <div class="call-target-menu-contents">
        <CallResultForm v-bind="callResultProps" />
        <OpportunityForm
          v-bind="opportunityProps"
          @after:create-with-sequence-association="$emit('fetchSequenceItems')"
        />
        <TargetFreeActionForm v-bind="freeActionProps" />
        <SingleNextActionForm v-bind="nextActionProps" />
        <SequenceStepForm
          :call-target="callTarget"
          @after-update="$emit('fetchSequenceItems')"
        />
      </div>
    </BLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MiitelWidget from '@/components/organisms/user/call_target/menu/MiitelWidget.vue';
import TargetFreeActionForm from '@/components/organisms/user/call_target/menu/TargetFreeActionForm.vue';
import ZoomPhoneWidget from '@/components/organisms/user/call_target/menu/ZoomPhoneWidget.vue';
import CallResultForm from '@/components/organisms/user/lead/menu/CallResultForm.vue';
import OpportunityForm from '@/components/organisms/user/lead/menu/OpportunityForm.vue';
import SequenceStepForm from '@/components/organisms/user/lead/menu/SequenceStepForm.vue';
import SingleNextActionForm from '@/components/organisms/user/lead/menu/SingleNextActionForm.vue';
import { useActiveCti } from '@/composable/active-cti';

export default {
  components: {
    MiitelWidget,
    ZoomPhoneWidget,
    CallResultForm,
    OpportunityForm,
    TargetFreeActionForm,
    SingleNextActionForm,
    SequenceStepForm,
  },
  props: {
    callTarget: Object,
    callTargetId: Number,
    activeSequenceId: Number,
    miitelCompanyId: String,
    miitelAccessKey: String,
    selectedCallResult: Object,
    users: Array,
  },
  emits: ['fetchSequenceItems'],
  setup () {
    const { activeCti } = useActiveCti(true);
    return {
      activeCti,
    };
  },
  data () {
    return {
      recreateState: true,
    };
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
    ]),
    callResultProps () {
      return {
        'call-target-id': this.callTargetId,
      };
    },
    opportunityProps () {
      return {
        callTargetId: this.callTargetId,
        activeSequenceId: this.activeSequenceId,
      };
    },
    freeActionProps () {
      return {
        callTargetId: this.callTargetId,
      };
    },
    nextActionProps () {
      return {
        callTargetId: this.callTargetId,
        currentUserId: this.currentUser.id,
      };
    },
    mailProps () {
      return {
        callTargetId: this.callTargetId,
      };
    },
  },
  watch: {
    miitelCompanyId () {
      this.recreateState = false;
      this.$nextTick(() => {
        this.recreateState = true;
      });
    },
    miitelAccessKey () {
      this.recreateState = false;
      this.$nextTick(() => {
        this.recreateState = true;
      });
    },
  },
  methods: {
    closeWidget () {
      if (this.$refs.miitelWidget?.closeWidget !== undefined) {
        this.$refs.miitelWidget.closeWidget();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.call-target-menu {
  $menu-width: 600px;
  position: absolute;
  top: 0;
  right: -$menu-width;
  z-index: 1;
  width: $menu-width;
  height: 100%;
  &-buttons {
    position: absolute;
    top: 80px;
    left: -60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .miitel-contents {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
  }
  .call-target-menu-contents {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
.call-target-menu-layout {
  height: 100%;
}
.menu-header {
  height: $header-height;
  width: 600px;
  border-bottom: 1px solid $bdcolor-light;
  padding-left: 20px;
  .header-title {
    font-size: $fontsize-400;
    font-weight: 600;
  }
}
</style>
