import ApiBase from '@/api/base';

export default {
  createCallResult (args) {
    const { callTargetId } = args;
    return ApiBase.post(`/user/call_targets/${callTargetId}/call_results`, args);
  },
  updateCallResult (args) {
    const { callResultId } = args;
    return ApiBase.put(`/user/call_results/${callResultId}`, args);
  },
  deleteCallResult (args) {
    const { callResultId } = args;
    return ApiBase.delete(`/user/call_results/${callResultId}`, args);
  },
};
