import lodash from 'lodash';
import { isEmptyValue } from '@/utils/emptyValue';

export const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

/**
 * オブジェクト以外の値が見つかるまでオブジェクトを再帰的に処理する
 * NOTE: Object.keys(val) の0番目を取っているため、オブジェクトのプロパティが2つ以上ある場合は正常に動作しない
 * NOTE: OK： { inc: { or: '' } }
 * NOTE: NG： { inc: { or: '' }, exc: { or: ''} }
*/
export const extractValueFromObject = (val) => {
  const child = isObject(val) ? val[Object.keys(val)[0]] : val;
  if (isObject(child)) {
    return extractValueFromObject(child);
  } else {
    return child;
  }
};

/**
 * 受け取ったオブジェクトのプロパティであるオブジェクトのうち値が空のものを削除して新たなオブジェクトを返す
 * NOTE: 対応するオブジェクトの形は { a: { b: '' }, c: { d: [] } } のようなもの
 */
export const removePropertyHasEmptyValue = (obj) => {
  obj = lodash.cloneDeep(obj);
  for (const key in obj) {
    const value = extractValueFromObject(obj[key]);
    const isEmpty = isEmptyValue(value);
    if (isEmpty) delete obj[key];
  }
  return obj;
};

/**
 * FIXME: この関数はドメイン知識の必要な処理であり、ここにあるべきではない。適宜移動する
 */
export const getIncExc = (obj) => {
  if (!isObject(obj)) return null;

  // MEMO: { inc: { or: [xx, xxx] } }という形で入っている前提で、0番目（inc/exc）を取得
  return Object.keys(obj)[0];
};
