<template>
  <div
    class="sfRelatedLeadBlock"
  >
    <div
      v-if="isError"
      class="header error-block"
    >
      <div class="error-title">
        <BIcon
          class="icon"
          type="error"
        >
          report_problem
        </BIcon>
        <span class="title">{{ $t('callTarget.sfSync.errorSync') }}</span>
      </div>
      <RouterLink
        class="error-link"
        to="/app/setting/salesforce/salesforce-error-logs"
      >
        {{ $t('general.detail') }}
      </RouterLink>
    </div>
    <div
      v-for="lead in sfRelatedLinkInfo.sfRelatedLinks"
      :key="lead.title"
      class="link-box"
    >
      <p
        v-if="!isError"
        class="header"
      >
        <BSfStatusIcon
          :connect-status="lead.status"
          class="icon"
        />
        <span class="title">{{ lead.title }}</span>
      </p>
      <div
        v-if="lead.status == 'synced'"
        class="url-content"
      >
        <div class="url-block">
          <p class="url">
            {{ lead.url }}
          </p>
        </div>
        <BLinkBtn
          :value="lead.url"
          btn-size="medium"
          class="link-btn"
        />
      </div>
      <div
        v-else-if="lead.status == 'sync_failed'"
        class="failed-content"
      >
        <p class="error-content">
          {{ lead.errorMessage }}
        </p>
      </div>
    </div>
    <div
      class="footer"
    >
      <div
        class="synced-date"
      >
        <p v-show="!isError">
          {{ $t(`callTarget.sfCheckLead.syncedDate`) }}： {{ sfRelatedLinkSyncedAt }}
        </p>
      </div>
      <div
        v-show="sfExecSyncBtn"
        class="exec-sync"
      >
        <BBtn
          v-if="isError"
          size="small"
          type="primary"
          class="sync-btn"
          :disabled="isLoading"
          @click="close"
        >
          <span>
            {{ $t('general.ok') }}
          </span>
        </BBtn>
        <BBtn
          v-else
          size="small"
          type="primary"
          class="sync-btn"
          :disabled="isLoading"
          @click="sfSync"
        >
          <BIcon
            class="icon-sync"
            size="b-icon-medium"
            type="white"
          >
            sync
          </BIcon>
          <span v-if="isLoading">
            {{ $t('callTarget.sfSync.loading') }}
          </span>
          <span v-else>
            {{ $t('callTarget.sfSync.exec') }}
          </span>
        </BBtn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { CallTargetApiService } from '@/api/user/resources/call_target';
import BSfStatusIcon from '@/plugins/biscuet-materials/components/molecules/BSfStatusIcon.vue';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  components: { BSfStatusIcon },
  props: {
    sfRelatedLinkInfo: {
      sfSyncedAt: Date,
      sfRelatedLinks: [{
        status: String,
        title: String,
        url: String,
        errorMessage: String,
      }],
    },
    sfExecSyncBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'is-loading',
    'click:modal-close',
  ],
  data: () => {
    return {
      isLoading: false,
      isError: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'callTarget',
    ]),
    sfRelatedLinkSyncedAt () {
      return formatShorterDateTime(this.sfRelatedLinkInfo.sfSyncedAt);
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetAction: 'getCallTargetWait',
    }),
    async sfSync() {
      this.isLoading = true;
      this.$emit('is-loading', this.isLoading);
      const api = new CallTargetApiService();
      const result = await api.callTargetSfSync({ request: { callTargetId: this.callTarget.id } });
      const synced = result.data.synced;
      const sfSyncedAt = result.data.sfSyncedAt;

      if (synced) {
        await this.getCallTargetAction({ request: { callTargetId: this.callTarget.id } });
        this.isLoading = false;
        this.$emit('is-loading', this.isLoading);
        this.$emit('click:modal-close');
      } else {
        this.isError = true;
        this.isLoading = false;
        this.$emit('is-loading', this.isLoading);
      }
    },
    async close() {
      await this.getCallTargetAction({ request: { callTargetId: this.callTarget.id } });
      this.$emit('click:modal-close');
    },
  },
};
</script>

<style  lang="scss">
  .sfRelatedLeadBlock {
    padding: 18px;
    position: relative;
    min-height: 140px;
    .link-box {
      background-color: $paper;
      margin-bottom: 14px;
      max-width: 454px;
      width: 100%;
      border-radius: 6px;
      .header {
        align-items: center;
        margin-bottom: 7px !important;
        display: flex;
        .icon {
          margin-left: 6px;
        }
        .title {
          margin-left: 6px;
          color: #000000;
          display: block;
          height: 29px;
          font-size: 18px;
        }
      }
      .url-content {
        display: flex;
        .url-block {
          line-height: 36px;
          border: 1px solid $concrete;
          box-sizing: border-box;
          border-radius: 4px;
          width: 370px;
          height: 36px;
          .url {
            margin-left: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .link-btn {
          margin-left: 12px;
        }
      }
      .failed-content {
        margin-left: 16px;
        .error-content {
          color: $basecolor-error;
          word-break: keep-all;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      .synced-date {
        p {
          font-size: 12px;
          color: $concrete-dark;
          margin-bottom: 0;
        }
      }
      .exec-sync {
        text-align: right;
        bottom: 8px;
        right: 16px;
        .icon-sync {
          margin-right: 4px;
          transform: rotate(90deg);
        }
      }
    }
    .error-block {
      padding: 12px 16px;
      margin: 2px 0;
      border-radius: 4px;
      background-color: #FFEEEE;
      text-align: center;
      color: #FF6465;
      justify-content: space-between;
      .error-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .error-link {
        color: #FF6465;
      }
      &.header {
        align-items: center;
        margin-bottom: 7px !important;
        display: flex;
        .icon {
          margin-left: 6px;
        }
        .title {
          margin-left: 6px;
          color: #FF6465;
          display: block;
          font-size: 14px;
        }
      }
    }
  }
</style>
