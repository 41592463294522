import { RouteRecordRaw } from 'vue-router';

import Analytics from '@/components/pages/user/Analytics.vue';
import Beta from '@/components/pages/user/Beta.vue';
import CallAnalytics from '@/components/pages/user/CallAnalytics.vue';
import Home from '@/components/pages/user/Home.vue';
import User from '@/components/pages/user/Index.vue';
import LeadList from '@/components/pages/user/LeadList.vue';
import Mail from '@/components/pages/user/Mail.vue';
import MailTemplate from '@/components/pages/user/MailTemplate.vue';
import RlsCheck from '@/components/pages/user/RlsCheck.vue';
import Sequence from '@/components/pages/user/Sequence.vue';
import SequenceLeads from '@/components/pages/user/SequenceLeads.vue';
import SequenceNew from '@/components/pages/user/SequenceNew.vue';
import Sequences from '@/components/pages/user/Sequences.vue';
import Setting from '@/components/pages/user/Setting.vue';
import Trigger from '@/components/pages/user/Trigger.vue';
import TriggerLeads from '@/components/pages/user/TriggerLeads.vue';
import Triggers from '@/components/pages/user/Triggers.vue';
import { i18nGlobal as i18n } from '@/i18n';

export const userRoutes: RouteRecordRaw[] = [
  {
    path: '/app',
    component: User,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
        meta: {
          rule: 'isUser',
          title: i18n.t('home.title'),
        },
      },
      {
        path: 'lead-list',
        name: 'LeadList',
        component: LeadList,
        meta: {
          rule: 'isUser',
          title: i18n.t('leadList.title'),
        },
      },
      {
        path: 'mail',
        name: 'Mail',
        component: Mail,
        meta: {
          rule: 'isUser',
          title: i18n.t('mail.title'),
        },
      },
      {
        path: 'mail-template',
        name: 'MailTemplate',
        component: MailTemplate,
        meta: {
          rule: 'isUser',
          title: i18n.t('mailTemplate.title'),
        },
      },
      {
        path: 'call-analytics',
        name: 'CallAnalytics',
        component: CallAnalytics,
        meta: {
          rule: 'isUser',
          title: i18n.t('callAnalytics.title'),
        },
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: Analytics,
        meta: {
          rule: 'isUser',
          title: i18n.t('analytics.title'),
        },
      },
      {
        path: 'beta',
        name: 'Beta',
        component: Beta,
        meta: {
          rule: 'isUser',
        },
      },
      {
        path: 'rls-check',
        name: 'rls-check',
        component: RlsCheck,
        meta: {
          rule: 'isUser',
        },
      },
      {
        path: '/app:pathMatch(.*)*',
        redirect: { name: 'Home' },
        meta: {
          rule: 'isUser',
        },
      },
      {
        path: '/app',
        redirect: { name: 'Home' },
        meta: {
          rule: 'isUser',
        },
      },
      {
        path: 'setting/:tab?/:subTab?',
        name: 'Setting',
        component: Setting,
        meta: {
          rule: 'isUser',
          title: i18n.t('corpSetting.title'),
        },
      },
      {
        path: 'sequences',
        name: 'Sequences',
        component: Sequences,
        meta: {
          rule: 'isUser',
          title: i18n.t('sequence.title'),
        },
      },
      {
        path: 'sequences/:id',
        name: 'Sequence',
        component: Sequence,
        meta: {
          rule: 'isUser',
          title: i18n.t('sequence.title'),
        },
      },
      {
        path: 'sequences/new',
        name: 'SequenceNew',
        component: SequenceNew,
        meta: {
          rule: 'isUser',
          title: i18n.t('sequence.title'),
        },
      },
      {
        path: 'sequences/:id/leads',
        name: 'SequenceLeads',
        component: SequenceLeads,
        meta: {
          rule: 'isUser',
          title: i18n.t('sequence.title'),
        },
      },
      {
        path: 'triggers',
        name: 'Triggers',
        component: Triggers,
        meta: {
          rule: 'isUser',
          title: i18n.t('trigger.title'),
        },
      },
      {
        path: 'triggers/new',
        name: 'TriggerNew',
        component: Trigger,
        meta: {
          rule: 'isUser',
          title: i18n.t('trigger.title'),
        },
      },
      {
        path: 'triggers/:id(\\d+)',
        name: 'Trigger',
        component: Trigger,
        meta: {
          rule: 'isUser',
          title: i18n.t('trigger.title'),
        },
      },
      {
        path: 'triggers/:id/leads',
        name: 'TriggerLeads',
        component: TriggerLeads,
        meta: {
          rule: 'isUser',
          title: i18n.t('trigger.title'),
        },
      },
    ],
  },
];
