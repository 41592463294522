import Swal from 'sweetalert';

// FIXME: そのうち src/composable/message-dialog.ts にまるごと移行する
export const bitterAlert = {
  show(params) {
    let buttonsCancelParams = true;
    if (typeof params?.buttonsCancel !== 'undefined') {
      buttonsCancelParams = params.buttonsCancel;
    }

    let buttonsConfirmParams = true;
    if (typeof params?.buttonsConfirm !== 'undefined') {
      buttonsConfirmParams = params.buttonsConfirm;
    }

    return Swal({
      title: params?.title || '確認',
      text: params?.text || '',
      closeOnClickOutside: params?.closeOnClickOutside || false,
      buttons: {
        cancel: buttonsCancelParams,
        confirm: buttonsConfirmParams,
      },
      className: params?.className || '',
    });
  },
};

export default {
  install(app) {
    /**
     * @duplicated instead of: import { bitterAlert } from '@/plugins/BBitterAlert'
     */
    app.config.globalProperties.$bitterAlert = bitterAlert;
  },
};
