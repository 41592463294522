import lodash from 'lodash';
import { Ref, computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { TActionWithDisabled, TFormSequenceStep, TGetStepsActions, TPartialFormSequenceStep, TStepType } from '@/components/organisms/user/general/sequence/types';
import { useNextActionTypes } from '@/composable/user/nextActionType/next-action-types';
import { DIRECT_MAIL_MANUAL_ACTION_TYPE_ID, DIRECT_MAIL_RESERVE_ACTION_TYPE_ID, getDefaultActionTypeId, getStepTypeByActionType } from '@/composable/user/sequence/post-put-body';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TFormMode = 'create' | 'edit';
type TSaveFunc = (item: TFormSequenceStep) => void;
type TOpenStepForm = (saveFunc: TSaveFunc, step: TPartialFormSequenceStep) => void;
type TSubmitType = 'save' | 'send';

const useSequenceStepActions = (
  getSequenceSteps: () => TFormSequenceStep[],
  getStepsActions: TGetStepsActions,
  handleUpdate?: (steps: TFormSequenceStep[]) => void,
) => {
  const internalSequenceSteps = ref<TFormSequenceStep[]>([]);
  const isMenuOpens = ref<boolean[]>([]);
  const stepsActionsWithDisabled = ref<TActionWithDisabled[][]>([]);

  watch(getSequenceSteps, (newValue) => {
    const steps = lodash.cloneDeep(newValue).sort((a, b) => a.sortOrder - b.sortOrder);
    internalSequenceSteps.value = steps;
  }, { immediate: true });

  watch(internalSequenceSteps, (newValue) => {
    isMenuOpens.value = newValue.map(() => false);
    stepsActionsWithDisabled.value = getStepsActions(newValue);
    if (handleUpdate !== undefined) handleUpdate(newValue);
  }, { immediate: true, deep: true });

  const closeMenu = (index: number) => {
    isMenuOpens.value = isMenuOpens.value.map((value, i) => i === index ? false : value);
  };

  return {
    internalSequenceSteps,
    isMenuOpens,
    stepsActionsWithDisabled,
    closeMenu,
  };
};

const useSequenceSelect = () => {
  const selectedSequenceStep = ref<TPartialFormSequenceStep | null>(null);
  const selectedStepType = computed(() => selectedSequenceStep.value ? getStepTypeByActionType(selectedSequenceStep.value.actionType) : null);

  return {
    selectedSequenceStep,
    selectedStepType,
  };
};

const useStepDetail = (
  internalSequenceSteps: Ref<TFormSequenceStep[]>,
) => {
  const {
    selectedSequenceStep,
    selectedStepType,
  } = useSequenceSelect();
  const { findNextActionType } = useNextActionTypes();

  const isStepDetailOpen = computed(() => selectedSequenceStep.value != null);
  const isNextActionDetailOpen = computed(() => isStepDetailOpen.value && selectedStepType.value === 'next_action');
  const isDirectMailDetailOpen = computed(() => isStepDetailOpen.value && selectedStepType.value === 'direct_mail');
  const selectedNextActionType = computed(() =>
    isNextActionDetailOpen.value ? findNextActionType(selectedSequenceStep.value.actionType) : null,
  );

  const openDetail = (index: number) => {
    const step = internalSequenceSteps.value[index];
    selectedSequenceStep.value = step;
  };
  const closeStepDetail = () => {
    selectedSequenceStep.value = null;
  };

  return {
    isNextActionDetailOpen,
    isDirectMailDetailOpen,
    openDetail,
    closeStepDetail,
    selectedNextActionType,
    detailSelectedSequenceStep: selectedSequenceStep,
  };
};

const useStepForm = () => {
  const {
    selectedSequenceStep,
    selectedStepType,
  } = useSequenceSelect();

  const doSave = ref<TSaveFunc>();
  const isStepFormOpen = computed(() => selectedSequenceStep.value != null);
  const isNextActionFormOpen = computed(() => isStepFormOpen.value && selectedStepType.value === 'next_action');
  const isDirectMailFormOpen = computed(() => isStepFormOpen.value && selectedStepType.value === 'direct_mail');
  const directMailSubmitType = computed(() => isDirectMailFormOpen.value && selectedSequenceStep.value?.status === 'running' ? 'send' : 'save');

  const openStepForm = (saveFunc: TSaveFunc, step: TPartialFormSequenceStep) => {
    setTimeout(() => {
      doSave.value = saveFunc;
      selectedSequenceStep.value = step;
    }, 200);
  };
  const closeStepForm = () => {
    doSave.value = undefined;
    selectedSequenceStep.value = null;
  };

  const saveStep = (item: TFormSequenceStep) => {
    doSave.value(item);
  };
  const saveStepAsDraft = (item: TFormSequenceStep) => saveStep({
    ...item,
    actionType: DIRECT_MAIL_MANUAL_ACTION_TYPE_ID,
  });
  const saveStepAsReserve = (item: TFormSequenceStep) => saveStep({
    ...item,
    actionType: DIRECT_MAIL_RESERVE_ACTION_TYPE_ID,
  });

  return {
    isNextActionFormOpen,
    isDirectMailFormOpen,
    directMailSubmitType,
    openStepForm,
    closeStepForm,
    saveStep,
    saveStepAsDraft,
    saveStepAsReserve,
    formSelectedSequenceStep: selectedSequenceStep,
  };
};

const useStepEditing = (
  internalSequenceSteps: Ref<TFormSequenceStep[]>,
  openStepForm: TOpenStepForm,
) => {
  const openStepFormToEdit = (targetIndex: number) => {
    openStepForm(updateItem, internalSequenceSteps.value[targetIndex]);
  };

  const updateItem = (item: TFormSequenceStep) => {
    const updated = internalSequenceSteps.value.map(e => {
      if (e.sortOrder === item.sortOrder) return item;
      return e;
    });
    internalSequenceSteps.value = updated;
  };

  return {
    openStepFormToEdit,
  };
};

const useStepCreating = (
  internalSequenceSteps: Ref<TFormSequenceStep[]>,
  openStepForm: TOpenStepForm,
) => {
  const openStepFormToCreate = (stepType: TStepType, targetIndex: number) => {
    const newStep: TPartialFormSequenceStep = {
      sortOrder: targetIndex,
      priority: 'middle',
      actionType: getDefaultActionTypeId(stepType),
      deletable: true,
    };
    openStepForm(insertItem, newStep);
  };
  const insertItem = (item: TFormSequenceStep) => {
    const newSequenceSteps = lodash.cloneDeep(internalSequenceSteps.value);
    newSequenceSteps.splice(item.sortOrder, 0, item);
    newSequenceSteps.forEach((e, i) => e.sortOrder = i);
    internalSequenceSteps.value = newSequenceSteps;
  };

  return {
    openStepFormToCreate,
  };
};

const useStepDeleting = (
  internalSequenceSteps: Ref<TFormSequenceStep[]>,
) => {
  const i18n = useI18n();

  const deleteStep = async (index: number) => {
    const currentItem = internalSequenceSteps.value[index];
    if (!currentItem) return;
  
    const ok = await bitterAlert.show({
      text: i18n.t('sequence.message.confirmStepsAction', {
        number: currentItem.sortOrder + 1,
        target: i18n.t('sequence.step.title'),
        confirm: i18n.t('general.confirm.of', { action: i18n.t('general.delete.text') }),
      }),
    });
    if (!ok) { return; }
  
    internalSequenceSteps.value.splice(index, 1);
    internalSequenceSteps.value.forEach((e, i) => e.sortOrder = i);
  };

  return {
    deleteStep,
  };
};

const useStepsOrder = (
  internalSequenceSteps: Ref<TFormSequenceStep[]>,
) => {
  const changeStepsOrder = (currentIndex: number, targetIndex: number) => {
    const newSequenceSteps = lodash.cloneDeep(internalSequenceSteps.value);
    const currentItem = newSequenceSteps[currentIndex];
    const targetItem = newSequenceSteps[targetIndex];
    if (!currentItem || !targetItem) return;

    // NOTE: 該当indexを新しい要素でreplace
    newSequenceSteps.splice(currentIndex, 1, { ...targetItem, sortOrder: currentItem.sortOrder });
    newSequenceSteps.splice(targetIndex, 1, { ...currentItem, sortOrder: targetItem.sortOrder });
    internalSequenceSteps.value = newSequenceSteps;
  };

  return {
    changeStepsOrder,
  };
};

export type {
  TFormMode,
  TSubmitType,
};
export {
  useSequenceStepActions,
  useStepDetail,
  useStepForm,
  useStepEditing,
  useStepCreating,
  useStepDeleting,
  useStepsOrder,
};
