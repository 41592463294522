<template>
  <div class="sequence-step-with-connector-container">
    <BTooltip
      bottom-end
      :show-arrow="false"
      :content="$t('sequence.step.skippedStep')"
      :disabled="!isSkipped"
    >
      <BTodoCard
        v-model:is-menu-open="internalIsMenuOpen"
        class="step-card"
        :class="{ 'disabled': isSkipped, 'current-step': isRunning, 'failed': isFailed, 'sending': isSending }"
        :is-done="isDone"
        :title="title"
        :content="content"
        :actions="actions"
        :expandable-html-content="expandableHtmlContent"
        :icon="icon"
        v-on="cardHandlers"
      >
        <template #sub-content>
          <div
            v-if="stepStats"
            class="step-stats"
          >
            <div
              v-for="stat in stepStats"
              :key="stat.key"
              class="pl-50"
            >
              <div class="step-stat-name">
                {{ stat.name }}
              </div>
              <div class="step-stat-value">
                {{ stat.value }}
                <span
                  v-if="stat.percent"
                  class="step-stat-percent"
                >{{ `(${stat.percent}%)` }}</span>
              </div>
            </div>
          </div>
        </template>
      </BTodoCard>
    </BTooltip>
    <BConnector
      :menus="menus"
      :add-button-position="addButtonPosition"
      :disabled-add-button="disabledAddButton"
      :add-button-disabled-tooltip="$t('sequence.step.limitMessage')"
      @click:add-button="handleAddClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { NextActionTypeOrDefaultType } from '@/api/user/models/next_action_types';
import { getDisplayContent, getDisplaySubContent, getStepTypeByActionType, isDirectMailActionType, isDirectMailStepExecuted } from '@/composable/user/sequence/post-put-body';
import { useSequenceStepComputedProperty } from '@/composable/user/sequence/step-computed-property';
import { TMenu } from '@/plugins/biscuet-materials/components/molecules/connector';
import { TActionWithDisabled, TFormSequenceStep, TMailAggregates, TStepEventType, TStepType, TStepStat } from './types';

type TProps = {
  sequenceStep: TFormSequenceStep;
  needsAddButton: boolean;
  isMenuOpen: boolean;
  disabledAddButton: boolean;
  nextActionType?: NextActionTypeOrDefaultType;
  actions: TActionWithDisabled[];
};

type TEmits = {
  (event: 'click:insertNextAction'): void;
  (event: 'click:insertDirectMail'): void;
  (event: TStepEventType): void;
  (event: 'update:isMenuOpen', value: boolean): void;
};

const props = withDefaults(defineProps<TProps>(), {
  nextActionType: undefined,
});
const emit = defineEmits<TEmits>();

const i18n = useI18n();
const menus: TMenu<TStepType> = [
  { key: 'next_action', label: i18n.t('sequence.step.types.nextAction') },
  { key: 'direct_mail', label: `${i18n.t('sequence.step.types.directMail')}` },
];

const addButtonPosition = computed(() => props.needsAddButton ? 'middle' : 'none');

const stepType = computed(() => getStepTypeByActionType(props.sequenceStep.actionType));

const expandableHtmlContent = computed(() => getDisplaySubContent(props.sequenceStep));

const { title, isSkipped } = useSequenceStepComputedProperty(() => props.sequenceStep);
const content = computed(() => getDisplayContent(props.sequenceStep));
const icon = computed(() => isDirectMailActionType(props.sequenceStep.actionType) ? 'b-mail' : 'b-next-action');

const internalIsMenuOpen = computed({
  get() {
    return props.isMenuOpen;
  },
  set(newValue) {
    emit('update:isMenuOpen', newValue);
  },
});
const isRunning = computed(() => props.sequenceStep.status === 'running');
const isFailed = computed(() => props.sequenceStep.status === 'failed');
const isSending = computed(() => isRunning.value && isDirectMailStepExecuted(props.sequenceStep));
const isDone = computed(() => props.sequenceStep.status === 'done');

const stepStats = computed<TStepStat[]>(() => {
  if (stepType.value !== 'direct_mail') return [];
  const aggregates = (props.sequenceStep as TMailAggregates).mailAggregates;
  if (aggregates == null) return [];
  return [
    { key: 'mailAggregates.all', name: i18n.t('sequence.mailAggregates.all'), value: aggregates.numberOfAll, percent: null },
    { key: 'mailAggregates.sent', name: i18n.t('sequence.mailAggregates.sent'), value: aggregates.numberOfSent, percent: aggregates.sentPerAll },
    { key: 'mailAggregates.opened', name: i18n.t('sequence.mailAggregates.opened'), value: aggregates.numberOfOpened, percent: aggregates.openedPerSent },
    { key: 'mailAggregates.clicked', name: i18n.t('sequence.mailAggregates.clicked'), value: aggregates.numberOfClicked, percent: aggregates.clickedPerSent },
    { key: 'mailAggregates.replied', name: i18n.t('sequence.mailAggregates.replied'), value: aggregates.numberOfReplied, percent: aggregates.repliedPerSent },
    { key: 'mailAggregates.failed', name: i18n.t('sequence.mailAggregates.failed'), value: aggregates.numberOfFailed, percent: aggregates.failedPerAll },
  ];
});

// NOTE: 任意のイベントをhandle＆発火
const cardHandlers = computed(() =>
  Object.fromEntries(
    props.actions
      .filter(({ disabled }) => !disabled)
      .map(({ event }) => [event, () => emit(event)]),
  ),
);

const handleAddClick = (stepType: TStepType) => {
  if (props.disabledAddButton) return;
  stepType === 'direct_mail' ? emit('click:insertDirectMail') : emit('click:insertNextAction');
};
</script>

<style lang="scss" scoped>
$disabled-opacity: 0.4;
.sequence-step-with-connector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .step-card {
    width: 100%;

    .step-stats {
      display: flex;
      flex-wrap: nowrap;
      padding-right: $basespace-100;

      .step-stat-name {
        background: $bgcolor-dark;
        border-radius: 4px;
        border: 1px solid transparent;
        font-size: $fontsize-100;
        color: $textcolor-light;
        word-break: keep-all;
        min-width: 65px;
        padding: 0 $basespace-50;
        text-align: center;
      }
      .step-stat-value {
        font-size: $fontsize-100;
        text-align: center;
        color: $textcolor-base;
        margin-top: 2px;

        .step-stat-percent {
          color: $textcolor-light;
        }
      }
    }
  }
  .disabled {
    opacity: $disabled-opacity;
  }
  .card-border {
    &.current-step {
      border-color: $basecolor-accent;
    }
    &.sending {
      border-color: $bdcolor-warning;
    }
    &.failed {
      border-color: $basecolor-error;
    }
  }
}
</style>
