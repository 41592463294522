<template>
  <BContent
    v-loading="loading"
    class="content"
  >
    <template #navbar>
      <VWait
        class="lead-view-area"
        for="none"
        :class="{ loaded: !$wait.is('getLeadViewActionWait') }"
        :visible="$wait.is('getLeadViewActionWait')"
      >
        <template #waiting>
          <BContentLoader>
            <rect
              x="0"
              y="0"
              rx="3"
              ry="3"
              width="150"
              height="10"
            />
          </BContentLoader>
        </template>
        <BCustomIcon
          icon-class="b-list"
          class="mt-100"
        />
        <LeadViewSelectPanel
          class="mb-300 ml-300"
          @after-set-lead-view="getCallTargetsAndMeta"
        />
        <BCustomIconText
          text-size="mini"
          hover
          icon-size="large"
          icon-class="b-call-analytics"
          class="ml-200"
          @click="moveAnalytics"
        >
          {{ $t('callAnalytics.title') }}
        </BCustomIconText>
        <BCustomIconText
          v-if="leadView.id"
          hover
          text-size="mini"
          icon-size="large"
          icon-class="b-list-update"
          class="ml-200"
          @click="$modal.show('LeadViewUpdateModal')"
        >
          {{ $t('general.overwriteSave') }}
        </BCustomIconText>
        <BTooltip top>
          <BCustomIconText
            hover
            text-size="mini"
            icon-size="large"
            icon-class="b-list-new"
            class="ml-200"
            @click="$modal.show('leadViewCreateModal')"
          >
            {{ $t('leadList.save.title') }}
          </BCustomIconText>
          <template #content>
            <div class="save-text">
              {{ $t('leadList.save.description') }}
            </div>
          </template>
        </BTooltip>
      </VWait>
      <div v-if="isAvailableSalesforceApiFeature">
        <BBtn
          type="primary"
          size="mini"
          class="mr-200"
          @click="$modal.show('SfLeadDataCheckModal')"
        >
          {{ $t('leadList.syncSalesforce.title') }}
        </BBtn>
      </div>
      <BDivider
        v-if="visibleMailButton"
        direction="vertical"
      />
      <BTooltip
        v-if="visibleMailButton"
        top
        :content="$t('mail.mailCreate')"
      >
        <BCustomIcon
          class="mr-100 ml-100"
          size="small"
          icon-class="b-mail"
          hover
          @click="showBulkMailForm"
        />
      </BTooltip>
      <BDivider direction="vertical" />
      <BTooltip
        top
        :content="$t('callList.import.title')"
      >
        <div>
          <BMenu
            v-model="isImportMenuVisible"
            placement="bottom"
          >
            <template #activator>
              <BCustomIcon
                class="mr-100 ml-100"
                size="small"
                icon-class="b-import"
                hover
              />
            </template>
            <BCard full>
              <div class="py-100">
                <BBtn
                  flat
                  fit
                  size="small"
                  @click="showImportModal('callTargetSetting')"
                >
                  <span>{{ $t('leadList.import.type.single') }}</span>
                </BBtn>
                <BBtn
                  flat
                  fit
                  size="small"
                  @click="showImportModal('CsvImportModal')"
                >
                  <span>{{ $t('leadList.import.type.csv') }}</span>
                </BBtn>
                <BBtn
                  flat
                  fit
                  size="small"
                  @click="showImportModal('ExcelImportModal')"
                >
                  <span>{{ $t('leadList.import.type.excel') }}</span>
                </BBtn>
                <BBtn
                  v-if="isAvailableSalesforceApiFeature"
                  flat
                  fit
                  size="small"
                  @click="showImportModal('SalesforceReportModal')"
                >
                  <span>{{ $t('leadList.import.type.salesforce') }}</span>
                </BBtn>
              </div>
            </BCard>
          </BMenu>
        </div>
      </BTooltip>
      <BTooltip
        top
        :content="$t('callList.update.title')"
      >
        <div>
          <BMenu
            v-model="isOverwriteMenuVisible"
            placement="bottom"
          >
            <template #activator>
              <BCustomIcon
                class="update-icon"
                size="small"
                icon-class="b-update"
                hover
              />
            </template>
            <BCard full>
              <div class="py-100">
                <BBtn
                  flat
                  fit
                  size="small"
                  @click="showOverwriteModal('CsvOverwriteModal')"
                >
                  <span>{{ $t('callList.update.type.csv') }}</span>
                </BBtn>
                <BBtn
                  flat
                  fit
                  size="small"
                  @click="showOverwriteModal('ExcelOverwriteModal')"
                >
                  <span>{{ $t('callList.update.type.excel') }}</span>
                </BBtn>
              </div>
            </BCard>
          </BMenu>
        </div>
      </BTooltip>
      <BTooltip
        v-if="$can('owner')"
        top
        :content="$t('callList.export.title')"
      >
        <BCustomIcon
          class="mx-100"
          size="small"
          icon-class="b-export"
          hover
          @click="$modal.show('LeadExportModal')"
        />
      </BTooltip>
      <BMoreMenu
        v-model="isDeleteMenuVisible"
        placement="bottom"
      >
        <BBtn
          flat
          fit
          size="small"
          @click="showDeleteModal('AllLeadDeleteModal')"
        >
          <span class="lead-delete">{{ $t('callTarget.destroyAll.doing') }}</span>
        </BBtn>
      </BMoreMenu>
    </template>
    <div class="call-list-template">
      <LeadCsvImportStatus @success="getCallTargets" />
      <LeadExcelImportStatus @success="getCallTargets" />
      <LeadCsvOverwriteStatus @success="getCallTargets" />
      <LeadExcelOverwriteStatus @success="getCallTargets" />
      <LeadDeleteStatus @success="getCallTargets" />
      <LeadMergeStatus @success="getCallTargets" />
      <CustomFieldDestroyStatus />
      <CustomFieldOptionDestroyStatus />
      <SfLeadDataCheckStatus
        v-if="isAvailableSalesforceApiFeature"
        @success="getCallTargets"
      />
      <LeadSfImportStatus
        v-if="isAvailableSalesforceApiFeature"
        @success="getCallTargets"
      />
      <div class="lead-list-control">
        <ListPageControl>
          <FilterSelect />
          <ListColumnSelect />
        </ListPageControl>
      </div>
      <div
        class="lead-list-area"
        :class="{ 'with-footer': selectedCallTargetIds.length > 0 }"
      >
        <LeadListChild
          :call-targets="callTargets"
          :call-targets-meta="callTargetsMeta"
          :custom-fields="customFields"
          @update:selected-call-target-ids="handleSelectedCallTargetIdsUpdate"
        />
      </div>
    </div>
    <CsvImportModal />
    <ExcelImportModal />
    <CsvOverwriteModal />
    <ExcelOverwriteModal />
    <ExportModal v-if="$can('owner')" />
    <LeadViewCreateModal />
    <LeadViewUpdateModal />
    <MailFormTransition :is-visible="mailFormVisible">
      <BulkMailForm
        v-model:bulk-mail="bulkMail"
        :can-duplicate="false"
        @cancel="hiddenMailForm"
        @bulk-mail-change="bulkMail = $event"
      />
    </MailFormTransition>
    <AllLeadDeleteModal />
    <div v-if="isAvailableSalesforceLinkFeature">
      <SfLeadDataCheckModal />
      <SalesforceReportModal />
    </div>
    <template #footer>
      <AssignOperatorControl
        v-if="isExistCallTargets"
        class="fixed-bottom"
        :call-targets="callTargets"
        :selected-call-target-ids="selectedCallTargetIds"
      />
    </template>
  </BContent>
</template>

<script lang="ts">
import camelcaseKeys from 'camelcase-keys';
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import AssignOperatorControl from '@/components/organisms/user/AssignOperatorControl.vue';
import CustomFieldDestroyStatus from '@/components/organisms/user/CustomFieldDestroyStatus.vue';
import CustomFieldOptionDestroyStatus from '@/components/organisms/user/CustomFieldOptionDestroyStatus.vue';
import LeadViewCreateModal from '@/components/organisms/user/general/modal/leadView/LeadViewCreateModal.vue';
import LeadMergeStatus from '@/components/organisms/user/lead/duplicatedList/LeadMergeStatus.vue';
import LeadCsvImportStatus from '@/components/organisms/user/leadImport/LeadCsvImportStatus.vue';
import LeadCsvOverwriteStatus from '@/components/organisms/user/leadImport/LeadCsvOverwriteStatus.vue';
import LeadDeleteStatus from '@/components/organisms/user/leadImport/LeadDeleteStatus.vue';
import LeadExcelImportStatus from '@/components/organisms/user/leadImport/LeadExcelImportStatus.vue';
import LeadExcelOverwriteStatus from '@/components/organisms/user/leadImport/LeadExcelOverwriteStatus.vue';
import LeadSfImportStatus from '@/components/organisms/user/leadImport/LeadSfImportStatus.vue';
import SfLeadDataCheckStatus from '@/components/organisms/user/leadImport/SfLeadDataCheckStatus.vue';
import AllLeadDeleteModal from '@/components/organisms/user/leadList/AllLeadDeleteModal.vue';
import FilterSelect from '@/components/organisms/user/leadList/filter/FilterSelect.vue';
import LeadListChild from '@/components/organisms/user/leadList/LeadList.vue';
import LeadViewUpdateModal from '@/components/organisms/user/leadList/LeadViewUpdateModal.vue';
import ListColumnSelect from '@/components/organisms/user/leadList/ListColumnSelect.vue';
import ListPageControl from '@/components/organisms/user/leadList/ListPageControl.vue';
import CsvImportModal from '@/components/organisms/user/leadList/modal/CsvImportModal.vue';
import CsvOverwriteModal from '@/components/organisms/user/leadList/modal/CsvOverwriteModal.vue';
import ExcelImportModal from '@/components/organisms/user/leadList/modal/ExcelImportModal.vue';
import ExcelOverwriteModal from '@/components/organisms/user/leadList/modal/ExcelOverwriteModal.vue';
import ExportModal from '@/components/organisms/user/leadList/modal/ExportModal.vue';
import SalesforceReportModal from '@/components/organisms/user/leadList/modal/SalesforceReportModal.vue';
import SfLeadDataCheckModal from '@/components/organisms/user/leadList/SfLeadDataCheckModal.vue';
import LeadViewSelectPanel from '@/components/organisms/user/LeadViewSelectPanel.vue';
import BulkMailForm from '@/components/organisms/user/mail/BulkMailForm.vue';
import MailFormTransition from '@/components/organisms/user/mail/common/drawer/MailFormTransition.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import checkLeadviewCondition from '@/mixins/check_leadview_condition';
import intercom from '@/mixins/intercom';
import queryParameter from '@/mixins/query_parameter';

export default defineComponent({
  components: {
    ExportModal,
    CsvImportModal,
    ExcelImportModal,
    LeadCsvImportStatus,
    LeadExcelImportStatus,
    CsvOverwriteModal,
    ExcelOverwriteModal,
    LeadCsvOverwriteStatus,
    LeadExcelOverwriteStatus,
    SfLeadDataCheckStatus,
    AssignOperatorControl,
    LeadListChild,
    LeadViewCreateModal,
    LeadViewUpdateModal,
    LeadViewSelectPanel,
    FilterSelect,
    ListColumnSelect,
    BulkMailForm,
    AllLeadDeleteModal,
    LeadDeleteStatus,
    SfLeadDataCheckModal,
    SalesforceReportModal,
    LeadSfImportStatus,
    MailFormTransition,
    LeadMergeStatus,
    CustomFieldDestroyStatus,
    CustomFieldOptionDestroyStatus,
    ListPageControl,
  },
  mixins: [queryParameter, intercom, checkLeadviewCondition],
  setup(props) {
    const { isAvailableSalesforceApiFeature, isAvailableSalesforceLinkFeature, isAvailableBulkMailFeature } = useAvailableFeatureCheck();
    return {
      isAvailableSalesforceApiFeature,
      isAvailableSalesforceLinkFeature,
      isAvailableBulkMailFeature,
      ...intercom.setup(props),
    };
  },
  data() {
    return {
      mailFormVisible: false,
      taskTimerObj: null,
      isImportMenuVisible: false,
      isOverwriteMenuVisible: false,
      isDeleteMenuVisible: false,
      bulkMail: {},
      selectedCallTargetIds: [],
    };
  },
  computed: {
    ...mapGetters('user', [
      'callTargets',
      'callTargetsMeta',
      'customFields',
      'leadView',
      'leadExcelImportTasks',
      'leadCsvImportTasks',
      'leadCsvOverwriteTasks',
      'leadExcelOverwriteTasks',
      'sfReportImportTasks',
    ]),
    ...mapGetters('userUi', [
      'leadListPageParams',
      'leadListFilterParams',
      'pendingCsvImportTaskIds',
      'pendingCsvOverwriteTaskIds',
      'pendingExcelImportTaskIds',
      'pendingExcelOverwriteTaskIds',
      'pendingSfImportTaskIds',
    ]),
    isExistCallTargets() {
      return this.callTargets.length > 0;
    },
    visibleMailButton() {
      return this.isAvailableBulkMailFeature && this.leadView.id;
    },
    loading() {
      return this.$wait.is(['getCallTargetsWait', 'getCallTargetsBackgroundWait']);
    },
  },
  async created() {
    this.$wait.start('initLeadListPage');
    this.setupTaskTimer();
    await this.clearLeadViewAction();

    const pageParams = this.getPageQueryParameter();
    this.setLeadListPageParamsAction(pageParams);

    const results = [];
    const leadViewId = this.getLeadViewQueryParameter();
    if (leadViewId) {
      await this.getLeadView(leadViewId);
      this.setLeadViewSettings(this.leadView);
    }

    results.push(this.getCallTargets());
    results.push(this.getUsersAction());
    results.push(this.getBulkMailCampaignNamesAction());

    await Promise.all(results);
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: {
        action: 'getCallTargetsAction',
        loader: 'getCallTargetsWait',
      },
      getCallTargetsMetaAction: {
        action: 'getCallTargetsMetaAction',
        loader: 'getCallTargetsMetaWait',
      },
      getCallTargetsBackgroundAction: {
        action: 'getCallTargetsAction',
        loader: 'getCallTargetsBackgroundWait',
      },
      getUsersAction: 'getUsersWait',
      getLeadViewAction: {
        action: 'getLeadViewAction',
        loader: 'getLeadViewActionWait',
      },
      clearLeadViewAction: 'clearLeadViewAction',
      getBulkMailCampaignNamesAction: {
        action: 'getBulkMailCampaignNamesAction',
        loader: 'getBulkMailCampaignNamesWait',
      },
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListFilterParamsWait',
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
      setLeadListSelectedColumnsAction: 'setLeadListSelectedColumnsWait',
      setLeadListSortKeyAction: 'setLeadListSortKeyWait',
      setLeadListSortOrderAction: 'setLeadListSortOrderWait',
      setPendingCsvImportTaskIdsAction: 'setPendingCsvImportTaskIdsWait',
      setPendingExcelImportTaskIdsAction: 'setPendingExcelImportTaskIdsWait',
      setPendingCsvOverwriteTaskIdsAction: 'setPendingCsvOverwriteTaskIdsWait',
      setPendingExcelOverwriteTaskIdsAction: 'setPendingExcelOverwriteTaskIdsWait',
      setPendingSfImportTaskIdsAction: 'setPendingSfImportTaskIdsWait',
    }),
    async moveAnalytics(){
      let check = true;
      if (this.isConditionChanged) {
        check = await this.$bitterAlert.show({
          title: this.$t('general.confirm.text'),
          text: this.$t('leadView.changeCaution'),
        });
        if (check) this.$router.push({ name: 'CallAnalytics', query: { leadViewId: this.leadView.id, notClearFilter: true } });
      } else {
        this.$router.push({ name: 'CallAnalytics', query: { leadViewId: this.leadView.id, notClearFilter: true } });
      }
    },
    async getLeadView(leadViewId) {
      try {
        await this.getLeadViewAction({
          leadViewId,
          errorHandlers: { 404 () {} },
        });
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.notExists', {
            target: this.$t('leadView.title'),
          }),
          type: 'error',
        });
      }
    },
    async getCallTargetsAndMeta() {
      await this.getCallTargetsBackgroundAction();
      this.getCallTargetsMetaAction();
    },
    async getCallTargets() {
      try {
        if (this.callTargets.length > 0) {
          this.$toast.show({
            message: this.$t('leadList.reacquisition.loading'),
            type: 'info',
          });
          await this.getCallTargetsAndMeta();
          this.$toast.show({
            message: this.$t('leadList.reacquisition.done'),
            type: 'success',
          });
        } else {
          await this.getCallTargetsAndMeta();
        }
      } finally {
        this.$wait.end('initLeadListPage');
      }
    },
    hiddenMailForm() {
      this.mailFormVisible = false;
    },
    setLeadViewSettings(leadView) {
      if (typeof leadView !== 'object' || Object.keys(this.leadView).length === 0) {
        return false;
      }
      const columns = this.$JSON.parse(leadView.displayColumns);
      this.setLeadListSelectedColumnsAction(columns);
      this.setLeadListSortKeyAction(leadView.sortKey);
      this.setLeadListSortOrderAction(leadView.sortOrder);
      this.setFilterParams(leadView.filterParams);
    },
    setupTaskTimer() {
      // ストアの各Tasksを見て、対応するpendingTaskIds配列に存在しながらも、すでに isSuccess 状態になってしまった task が存在する場合、
      // 対応するpendingTaskIds配列から 該当の taskId を削除したうえで、リードの再取得処理を行う
      this.taskTimerObj = this.$setInterval(() => {
        if (!this.existSomePendingTasks) return false;

        const taskTypes = this.makeTaskTypes();
        for (const taskType of taskTypes) {
          const isExistSuccessfulPendingTask = this.tasksProcedure(taskType);
          if (isExistSuccessfulPendingTask) {
            this.getCallTargets();
            break;
          }
        }
      }, 2000);
    },
    existSomePendingTasks() {
      return (
        this.pendingCsvImportTaskIds.length > 0
        || this.pendingCsvOverwriteTaskIds.length > 0
        || this.pendingExcelImportTaskIds.length > 0
        || this.pendingSfImportTaskIds.length > 0
      );
    },
    makeTaskTypes() {
      return [
        {
          targetTasks: this.leadCsvImportTasks,
          pendingTaskIds: this.pendingCsvImportTaskIds,
          setAction: this.setPendingCsvImportTaskIdsAction,
        },
        {
          targetTasks: this.leadCsvOverwriteTasks,
          pendingTaskIds: this.pendingCsvOverwriteTaskIds,
          setAction: this.setPendingCsvOverwriteTaskIdsAction,
        },
        {
          targetTasks: this.leadExcelImportTasks,
          pendingTaskIds: this.pendingExcelImportTaskIds,
          setAction: this.setPendingExcelImportTaskIdsAction,
        },
        {
          targetTasks: this.leadExcelOverwriteTasks,
          pendingTaskIds: this.pendingExcelOverwriteTaskIds,
          setAction: this.setPendingExcelOverwriteTaskIdsAction,
        },
        {
          targetTasks: this.sfReportImportTasks,
          pendingTaskIds: this.pendingSfImportTaskIds,
          setAction: this.setPendingSfImportTaskIdsAction,
        },
      ];
    },
    tasksProcedure({ targetTasks, pendingTaskIds, setAction }) {
      const successfulPendingTaskIds = this.extractSuccessfulTasks(targetTasks, pendingTaskIds);
      if (successfulPendingTaskIds.length > 0) {
        this.removeSpecificTaskIdsFromPendingTasks(pendingTaskIds, successfulPendingTaskIds, setAction);
        return true;
      } else {
        return false;
      }
    },
    extractSuccessfulTasks(targetTasks, pendingTaskIds) {
      const successfulPendingTaskIds = [];
      const pendingTasks = targetTasks.filter((task) => pendingTaskIds.includes(task.id));
      pendingTasks.forEach((task) => {
        if (this.isSuccessTask(task)) successfulPendingTaskIds.push(task.id);
      });
      return successfulPendingTaskIds;
    },
    removeSpecificTaskIdsFromPendingTasks(pendingTaskIds, successfulPendingTaskIds, setAction) {
      const newPendingIds = pendingTaskIds.filter((id) => !successfulPendingTaskIds.includes(id));
      setAction(newPendingIds);
    },
    isSuccessTask(task) {
      const existTargetTask = typeof task.id === 'number';
      const existFinishedAt = typeof task.finishedAt === 'string' && task.finishedAt.length > 0;
      const noError = task.errorData == null;
      return existTargetTask && existFinishedAt && noError;
    },
    setFilterParams(filterParams) {
      if (filterParams == null) return false;
      filterParams = camelcaseKeys(this.$JSON.parse(filterParams), {
        deep: true,
        exclude: [/^custom_field_.*[^items]/g],
      });
      if (Object.keys(filterParams).length > 0) {
        this.setLeadListFilterParamsAction(filterParams);
        this.setFilterControlPanelVisibleAction(true);
      }
    },
    showImportModal(modal: string) {
      this.isImportMenuVisible = false;
      this.$modal.show(modal);
    },
    showOverwriteModal(modal: string) {
      this.isOverwriteMenuVisible = false;
      this.$modal.show(modal);
    },
    showDeleteModal(modal: string) {
      this.isDeleteMenuVisible = false;
      this.$modal.show(modal);
    },
    async showBulkMailForm() {
      this.mailFormVisible = true;
      this.$nextTick(() => {
        this.bulkMail.leadViewId = this.leadView.id;
      });
    },
    handleSelectedCallTargetIdsUpdate(selectedCallTargetIds: number[]) {
      this.selectedCallTargetIds = selectedCallTargetIds;
    },
  },
});
</script>

<style lang="scss" scoped>
.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.save-title {
  color: $basecolor-primary;
}

.save-text {
  margin: 0;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.lead-view-area {
  width: 100%;
  height: 34px;

  &.loaded {
    display: flex;
  }
}

.list-item {
  display: block;
  color: $textcolor-base;
  line-height: 2;
  text-decoration: none;
  transition: $transition-base;
  &:hover {
    opacity: 0.4;
  }
  &.router-link-active {
    font-weight: 600;
  }
}

.call-list-template {
  @include m-fixed-height(100%);
  overflow-y: hidden;
}


.lead-list-area {
  @include m-fixed-height(calc(100% - 60px));
  overflow-y: auto;
  &.with-footer {
    // NOTE: footerぶん引く
    @include m-fixed-height(calc(100% - 60px - 72px));
  }
}

:deep(.list-name-width > i.b-icon) {
  margin-left: 8px;
  margin-bottom: -3px;
}

.lead-delete {
  color: $basecolor-secondary;
}

.update-icon {
  margin-left: 8px;
  margin-right: 8px;
}
.icon-text {
  font-size: $fontsize-100;
}
.content {
  position: relative;

  & > :deep(.el-loading-mask) {
    z-index: 3; // NOTE: リードリスト < リードリスト上のローディング < これ
  }
}
</style>
