import { RouteRecordRaw } from 'vue-router';
import AdminSignin from '@/components/pages/auth/AdminSignin.vue';
import LoginEmailConfirm from '@/components/pages/auth/LoginEmailConfirm.vue';
import SalesforceCallback from '@/components/pages/auth/SalesforceCallback.vue';
import UserResetPassword from '@/components/pages/auth/UserResetPassword.vue';
import UserSenderEmailConfirm from '@/components/pages/auth/UserSenderEmailConfirm.vue';
import UserSignin from '@/components/pages/auth/UserSignin.vue';
import ZoomCallback from '@/components/pages/auth/ZoomCallback.vue';
import Error from '@/components/pages/Error.vue';
import Unsubscribe from '@/components/pages/Unsubscribe.vue';
import { i18nGlobal as i18n } from '@/i18n';

export const authRoutes: RouteRecordRaw[] =  [
  {
    path: '/admin/login',
    name: 'AdminSignin',
    component: AdminSignin,
    meta: {
      rule: 'all',
    },
  },
  {
    path: '/app/login',
    name: 'UserSignin',
    component: UserSignin,
    meta: {
      rule: 'all',
      title: i18n.t('auth.title'),
    },
  },
  {
    path: '/app/reset-password',
    name: 'UserResetPassword',
    component: UserResetPassword,
    meta: {
      rule: 'all',
    },
  },
  {
    path: '/app/sender-email/confirm/:token',
    name: 'UserSenderEmailConfirm',
    component: UserSenderEmailConfirm,
    meta: {
      rule: 'all',
    },
  },
  {
    path: '/app/login-email/confirm/:token',
    name: 'LoginEmailConfirm',
    component: LoginEmailConfirm,
    meta: {
      rule: 'all',
    },
  },
  {
    path: '/app/salesforce/oauth2/callback',
    name: 'SalesforceCallback',
    component: SalesforceCallback,
    meta: {
      rule: 'all',
    },
  },
  {
    path: '/app/zoom/oauth/callback',
    name: 'ZoomCallback',
    component: ZoomCallback,
    meta: {
      rule: 'all',
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      rule: 'all',
    },
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: Unsubscribe,
    meta: {
      rule: 'all',
    },
  },
];
