<template>
  <LeadHistoryCard
    :text="callResult.name"
    :user="callResult.operator"
    icon="call"
    :icon-type="iconType"
    toggle
    :expand="expand"
  >
    <template #date>
      <div>{{ startedAtDate }} {{ startedAtTime }} - {{ endedAtTime }}</div>
    </template>
    <template #action>
      <div>
        <BLayout align-center>
          <BBtn
            v-if="isAvailableSalesforceApiFeature"
            class="mt-2 mr-2"
            size="small"
            fab
            flat
          >
            <BSfStatusIcon
              :connect-status="sfConnectedStatus"
              @open-sf-modal="openSfModal(sfCallResultTaskName)"
            />
          </BBtn>
          <SfStatusModal
            v-if="isAvailableSalesforceApiFeature"
            :modal-name="sfCallResultTaskName"
            :sf-related-link-info="callResult.sfRelatedLinkInfo"
            :sf-exec-sync-btn="false"
          />
          <BBtn
            v-if="callResult.miitelResultUrl"
            size="small"
            fab
            flat
          >
            <a
              class="miitel-btn"
              :href="callResult.miitelResultUrl"
              target="_blank"
              rel="noopener noreferrer"
            ><img
              class="miitel-logo"
              :src="miitelIcon"
            ></a>
          </BBtn>
          <BBtn
            size="small"
            fab
            flat
            data-test="edit"
            @click.stop="$emit('click-edit', callResult)"
          >
            <BIcon size="small">
              edit
            </BIcon>
          </BBtn>
          <BBtn
            size="small"
            fab
            flat
            data-test="edit"
            @click.stop="$emit('click-delete', callResult.id)"
          >
            <BIcon size="small">
              delete_forever
            </BIcon>
          </BBtn>
        </BLayout>
      </div>
    </template>
    <div class="history-card-body">
      <template v-if="isZoomPhoneRecordingVisible">
        <ZoomPhoneRecording
          :zoom-phone-recording="callResult.zoomPhoneRecording"
          :width="500"
        />
        <BDivider
          class="my-600"
        />
      </template>
      <template v-if="callResult.customActionValues?.length">
        <CustomActionValues
          :custom-action-values="callResult.customActionValues"
        />
        <BDivider
          class="my-600"
        />
      </template>
      <BEditableContentTextarea
        v-model="comment"
        class="card-item callresult-comment-area"
        :placeholder="$t('callResult.comment.text')"
        @check="updateCallResult($event)"
        @click="setTargetHeaderMoveButtonDisable(true)"
        @cancel="setTargetHeaderMoveButtonDisable(false)"
      />
      <BLayout
        v-if="comment"
        justify-end
        class="mt-50"
      >
        <BCopyButton
          :text="comment"
          :title="$t('callResult.comment.text')"
          is-detailed-name
        />
      </BLayout>
    </div>
  </LeadHistoryCard>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import miitelIcon from '@/assets/miitel.png';
import SfStatusModal from '@/components/organisms/user/general/modal/salesforce/SfStatusModal.vue';
import ZoomPhoneRecording from '@/components/organisms/user/lead/general/ZoomPhoneRecording.vue';
import CustomActionValues from '@/components/organisms/user/lead/history/CustomActionValues.vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import errorHandler from '@/mixins/error_handler';
import BSfStatusIcon from '@/plugins/biscuet-materials/components/molecules/BSfStatusIcon.vue';
import { formatShorterDate, formatShortTime } from '@/utils/date-time';

export default {
  components: {
    LeadHistoryCard,
    ZoomPhoneRecording,
    SfStatusModal,
    BSfStatusIcon,
    CustomActionValues,
  },
  mixins: [errorHandler],
  props: {
    callResult: Object,
    disabled: Boolean,
    editable: {
      type: Boolean,
      default: true,
    },
    dateOnly: Boolean,
    expand: Boolean,
  },
  emits: [
    'click-edit',
    'click-delete',
  ],
  setup() {
    const { isAvailableSalesforceApiFeature } = useAvailableFeatureCheck();
    return {
      isAvailableSalesforceApiFeature,
      miitelIcon,
    };
  },
  data () {
    return {
      comment: this.callResult.comment,
    };
  },
  computed: {
    iconType () {
      if (this.callResult.connected) {
        return 'hot';
      } else {
        return 'cold';
      }
    },
    visible () {
      return this.callResult.comment || this.callResult.miitelResultUrl || this.callResult.fieldValues.length !== 0;
    },
    startedAtDate () {
      return formatShorterDate(this.callResult.startedAt);
    },
    startedAtTime () {
      return formatShortTime(this.callResult.startedAt);
    },
    endedAtTime () {
      return formatShortTime(this.callResult.endedAt);
    },
    sfConnectedStatus() {
      return this.callResult.sfRelatedLinkInfo?.sfRelatedLinks[0]?.status || '';
    },
    sfCallResultTaskName() {
      return `sfTaskStatusCallResultModal${this.callResult.id}`;
    },
    isZoomPhoneRecordingVisible() {
      return this.callResult.zoomPhoneRecording?.id || this.callResult.zoomPhoneRecording?.inProgress || this.callResult.zoomPhoneRecording?.expired;
    },
  },
  watch: {
    'callResult.comment' (newVal) {
      this.comment = newVal;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      updateCallResultAction: 'updateCallResultWait',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
    }),
    ...mapWaitingActions('userUi', {
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
    }),
    openSfModal(modalName) {
      this.$modal.show(modalName);
    },
    setTargetHeaderMoveButtonDisable (status) {
      this.setTargetHeaderMoveButtonDisabledAction(status);
    },
    async updateCallResult () {
      await this.updateCallResultAction({
        callTargetId: this.callResult.callTargetId,
        callResultId: this.callResult.id,
        body: {
          callResult: {
            ...this.callResult,
            comment: this.comment,
          },
        },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
      this.setTargetHeaderMoveButtonDisabledAction(false);
      this.getLeadHistoriesBackgroundAction({ callTargetId: this.callResult.callTargetId });
      this.getCallTargetsBackgroundAction({ callListId: this.callResult.callListId });
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-call-result-card {
    padding: $basespace-500 16px $basespace-500 80px
  }

  .history-card-body {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .miitel-btn {
    display: flex;
    align-items: center;

    .miitel-logo {
      height: 12px;
    }
  }

  .callresult-comment-area {
    margin-left: -$basespace-100;
    margin-right: -$basespace-100;
  }
</style>
