<template>
  <BTooltip
    top
    :content="tooltipMessage"
  >
    <BBtn
      type="primary"
      size="mini"
      fab
      :disabled="disabled"
      @click="$emit('click')"
    >
      <BIcon
        size="small"
        type="white"
      >
        phone_in_talk
      </BIcon>
    </BBtn>
  </BTooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    tooltipMessage: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'click',
  ],
});
</script>
