<template>
  <div
    class="sequence-steps-container"
  >
    <EditableSequenceSteps
      v-model:is-menu-opens="isMenuOpens"
      :sequence-steps="internalSequenceSteps"
      :enabled-to-add-steps="false"
      :steps-actions-with-disabled="stepsActionsWithDisabled"
      v-on="stepHandlers"
    />
  </div>
  <div class="side-menu-container">
    <SequenceNextActionDetail
      :sequence-step="(detailSelectedSequenceStep as TFormNextActionSequenceStep)"
      :is-open="isNextActionDetailOpen"
      :next-action-type="selectedNextActionType"
      @click:cancel="handleCloseStepDetail"
    />
  </div>
  <div class="side-menu-container">
    <SequenceNextActionForm
      :sequence-step="(formSelectedSequenceStep as TPartialFormNextActionSequenceStep)"
      :is-open="isNextActionFormOpen"
      :loading="loading"
      @click:cancel="handleFormCancel"
      @click:save="handleFormSave"
    />
  </div>
  <div class="side-menu-container">
    <SequenceDirectMailForm
      mode="confirm"
      :sequence-step="(detailSelectedSequenceStep as TPartialFormDirectMailSequenceStep)"
      :sender-id="ownerId"
      :is-open="isDirectMailDetailOpen"
      :loading="loading"
      @click:cancel="handleCloseStepDetail"
    />
  </div>
  <div class="side-menu-container">
    <SequenceDirectMailForm
      mode="form"
      :sequence-step="(formSelectedSequenceStep as TPartialFormDirectMailSequenceStep)"
      :sender-id="ownerId"
      :is-open="isDirectMailFormOpen"
      :loading="loading"
      :save-button-text="$t('mail.reserve')"
      :can-confirm="canConfirmDirectMail"
      :submit-type="directMailSubmitType"
      :send-to-address="toEmail"
      @click:cancel="handleFormCancel"
      @click:confirm="handleDirectMailConfirm"
      @click:save-as-draft="handleDirectMailFormSaveAsDraft"
      @click:save-as-reserve="handleDirectMailFormReserve"
      @click:draft="handleDirectMailFormDraft"
      @click:reserve="handleDirectMailFormReserve"
      @click:send-immediately="handleDirectMailFormSendImmediately"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApiArguments } from '@/api/api_service';
import {
  CallTarget,
  SequenceStepBody,
  SequenceStepInstance,
  SequenceStepInstanceApiGetSequenceStepInstancesRequest,
  SequenceStepInstanceApiPutSequenceStepInstanceRequest,
} from '@/api/openapi';
import { SequenceStepInstanceApiService } from '@/api/user/resources/sequence_step_instance';
import { makeNextActionType } from '@/composable/user/nextActionType/next-action-types';
import { getActionDetail, isDirectMailActionType } from '@/composable/user/sequence/post-put-body';
import { useSequenceStepActions, useStepDetail, useStepEditing, useStepForm } from '@/composable/user/sequence/step-actions';
import { useSequenceStepInstanceActions } from '@/composable/user/sequence/step-menu-actions';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { sleep } from '@/utils/sleep';
import EditableSequenceSteps from '../general/sequence/EditableSequenceSteps.vue';
import SequenceDirectMailForm from '../general/sequence/SequenceDirectMailForm.vue';
import SequenceNextActionDetail from '../general/sequence/SequenceNextActionDetail.vue';
import SequenceNextActionForm from '../general/sequence/SequenceNextActionForm.vue';
import { TFormNextActionSequenceStep, TFormDirectMailSequenceStep, TFormSequenceStep, TPartialFormDirectMailSequenceStep, TPartialFormNextActionSequenceStep, TStepHandlers } from '../general/sequence/types';

type TProps = {
  callTarget: CallTarget | null;
  sequenceInstanceId: number;
  ownerId: number | undefined;
  loading: boolean;
};
type TEmit = {
  'afterUpdate': [];
  'beforeSend': [];
  'stopSending': [];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();
const sequenceStepInstances = ref<SequenceStepInstance[]>([]);
const i18n = useI18n();
const api = new SequenceStepInstanceApiService();
const { doActionWithWait } = useWait();

const { getStepsActions } = useSequenceStepInstanceActions();
const stepHandlers: TStepHandlers = {
  'click:detail': (index: number) => handleDetailClick(index),
  'click:edit': (index: number) => handleEditClick(index),
  'click:skip': (index: number) => handleSkipClick(index),
  'click:sendImmediately': (index: number) => handleSendImmediatelyClick(index),
  'click:startImmediately': (index: number) => handleStartImmediatelyClick(index),
};

const sequenceStepData = computed(() =>
  sequenceStepInstances.value.map(({ actionTypeId, defaultActionTypeId, ...data }): TFormSequenceStep => ({
    ...data,
    actionType: makeNextActionType(defaultActionTypeId, actionTypeId),
  })),
);

const toEmail = computed(() =>
  props.callTarget?.callTargetInfo?.email,
);

const {
  internalSequenceSteps,
  isMenuOpens,
  stepsActionsWithDisabled,
  closeMenu,
} = useSequenceStepActions(
  () => sequenceStepData.value,
  getStepsActions,
);
const {
  isNextActionFormOpen,
  isDirectMailFormOpen,
  directMailSubmitType,
  openStepForm,
  closeStepForm,
  saveStep,
  formSelectedSequenceStep,
} = useStepForm();
const {
  openDetail,
  isNextActionDetailOpen,
  isDirectMailDetailOpen,
  selectedNextActionType,
  closeStepDetail,
  detailSelectedSequenceStep,
} = useStepDetail(internalSequenceSteps);
const { openStepFormToEdit } = useStepEditing(internalSequenceSteps, openStepForm);

const fetchSequenceStepInstances = async (sequenceInstanceId: number) => {
  const apiArguments: ApiArguments<SequenceStepInstanceApiGetSequenceStepInstancesRequest> = {
    request: { sequenceInstanceId },
  };
  await doActionWithWait('fetchSequenceStepInstances', async () => {
    const res = await api.getSequenceStepInstances(apiArguments);
    sequenceStepInstances.value = res.data;
  });
};

watch(() => props.sequenceInstanceId, async (sequenceInstanceId) => {
  await fetchSequenceStepInstances(sequenceInstanceId);
}, { immediate: true });

const handleEditClick = (index: number) => {
  closeStepForm();
  closeMenu(index);
  openStepFormToEdit(index);
};
const handleDetailClick = (index: number) => {
  closeMenu(index);
  openDetail(index);
};
const handleSkipClick = async (index: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmStepToSkip', { number: index + 1 }),
  });
  closeMenu(index);
  if (!ok) {
    return;
  }
  skipStep(internalSequenceSteps.value[index]);
};
const handleSendImmediatelyClick = async (index: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmSendImmediately', { number: index + 1 }),
  });
  closeMenu(index);
  if (!ok) {
    return;
  }
  executeImmediately(internalSequenceSteps.value[index]);
};

const handleStartImmediatelyClick = async (index: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmStartImmediately', { number: index + 1 }),
  });
  closeMenu(index);
  if (!ok) {
    return;
  }
  executeImmediately(internalSequenceSteps.value[index]);
};

const handleCloseStepDetail = () => {
  closeStepDetail();
};

const handleFormCancel = () => {
  closeStepForm();
};
const afterUpdateByForm = (item: TFormSequenceStep) => {
  fetchSequenceStepInstances(props.sequenceInstanceId);
  emit('afterUpdate');
  saveStep(item);
};
const handleFormSave = async (sequenceStep: TFormSequenceStep) => {
  await doActionWithWait('putSequenceStepInstance', async () => {
    await api.putSequenceStepInstance({
      request: makeUpdateRequest(sequenceStep),
    });
  });
  afterUpdateByForm(sequenceStep);
  closeStepForm();
};
const canConfirmDirectMail = async (sequenceStep: TFormSequenceStep) => {
  try {
    if (directMailSubmitType.value === 'send') {
      // NOTE: 下書き保存成功したら遷移OK
      await draftDirectMailStep(sequenceStep);
      afterUpdateByForm(sequenceStep);
      // NOTE: steps再読み込みに伴ってconfirm画面がちらつくので、confirmへの遷移を一旦待つ
      await sleep(500);
    }
    return true;
  } catch (_e) {
    return false;
  }
};
const handleDirectMailFormSaveAsDraft = async (sequenceStep: TFormSequenceStep) => {
  // NOTE: 「下書き保存」と「下書きとして設定」は同じエンドポイント
  await draftDirectMailStep(sequenceStep);
  afterUpdateByForm(sequenceStep);
  closeStepForm();
};
const handleDirectMailFormDraft = async (sequenceStep: TFormSequenceStep) => {
  // NOTE: 「下書き保存」と「下書きとして設定」は同じエンドポイント
  await draftDirectMailStep(sequenceStep);
  afterUpdateByForm(sequenceStep);
};
const handleDirectMailFormReserve = async (sequenceStep: TFormSequenceStep) => {
  // NOTE: 「配信予約」と「配信予約として設定」は同じエンドポイント
  await doActionWithWait('reserveDirectMailSequenceStepInstance', async () => {
    await api.reserveDirectMailSequenceStepInstance({
      request: makeUpdateRequest(sequenceStep),
    });
  });
  afterUpdateByForm(sequenceStep);
  closeStepForm();
};
const handleDirectMailFormSendImmediately = async (sequenceStep: TFormSequenceStep) => {
  await executeImmediately(sequenceStep);
};
const handleDirectMailConfirm = async (sequenceStep: TFormDirectMailSequenceStep) => {
  // NOTE: subject, content のプレースホルダーを置換する
  await previewSequenceStepInstance(sequenceStep);
  saveStep(sequenceStep);
};

const skipStep = async (sequenceStep: TFormSequenceStep) => {
  const apiArguments: ApiArguments<SequenceStepInstanceApiPutSequenceStepInstanceRequest> = {
    request: {
      sequenceInstanceId: props.sequenceInstanceId,
      sequenceStepInstanceId: sequenceStep.id,
    },
  };
  await doActionWithWait('skipSequenceStepInstance', async () => {
    await api.skipSequenceStepInstance(apiArguments);
  });
  fetchSequenceStepInstances(props.sequenceInstanceId);
  emit('afterUpdate');
};
const makeUpdateRequest = (sequenceStep: TFormSequenceStep): SequenceStepInstanceApiPutSequenceStepInstanceRequest => {
  // NOTE: TFormSequenceStepからpost用の型に詰め替える
  const sequenceStepInstanceBody = {
    id: sequenceStep.id,
    actionType: sequenceStep.actionType,
    dateInterval: sequenceStep.dateInterval,
    reservedTime: sequenceStep.reservedTime,
    actionDetail: getActionDetail(sequenceStep),
    priority: sequenceStep.priority,
  } as SequenceStepBody;

  return {
    sequenceInstanceId: props.sequenceInstanceId,
    sequenceStepInstanceId: sequenceStep.id,
    postSequenceStepInstanceBody: {
      sequenceStep: sequenceStepInstanceBody,
    },
  };
};
const draftDirectMailStep = async (sequenceStep: TFormSequenceStep) => {
  await doActionWithWait('draftDirectMailSequenceStepInstance', async () => {
    await api.draftDirectMailSequenceStepInstance({
      request: makeUpdateRequest(sequenceStep),
    });
  });
};
const executeImmediately = async (sequenceStep: TFormSequenceStep) => {
  if (isDirectMailActionType(sequenceStep.actionType)) {
    emit('beforeSend');
  }
  try {
    await doActionWithWait('executeSequenceStepInstance', async () => {
      await api.executeSequenceStepInstance({
        request: {
          sequenceInstanceId: props.sequenceInstanceId,
          sequenceStepInstanceId: sequenceStep.id,
        },
      });
    });
    fetchSequenceStepInstances(props.sequenceInstanceId);
    emit('afterUpdate');
  } catch (_e) {
    emit('stopSending');
  }
};
const previewSequenceStepInstance = async (sequenceStep: TFormDirectMailSequenceStep) => {
  if (!isDirectMailActionType(sequenceStep.actionType)) return;

  await doActionWithWait('previewSequenceStepInstance', async () => {
    const { data } = await api.previewSequenceStepInstance({
      request: makeUpdateRequest(sequenceStep),
    });
    sequenceStep.replacedSubject = data.replacedSubject;
    sequenceStep.replacedContent = data.replacedContent;
  });
};
</script>

<style scoped lang="scss">
.side-menu-container {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
}
.sequence-steps-container {
  height: 100%;

  :deep(.sequence-step-container) {
    .steps-container {
      .scrollable-container--inner, .step-end {
        width: 480px;
        max-width: 480px;
        margin-left: 60px;
        margin-right: auto;
      }
    }
  }
}
</style>
