<template>
  <LeadHistoryCard
    :text="nextActionText"
    :user="nextAction.doneBy || {id: null}"
    icon="check"
    icon-type="success"
  >
    <template #date>
      <div>{{ getTimeWithDateAndTimeFilter(nextAction.doneAt) }}</div>
    </template>
    <template #action>
      <BTooltip top>
        <BBtn
          v-if="deletable"
          size="small"
          fab
          flat
          @click.stop="handleDoneCancel"
        >
          <BIcon size="small">
            clear
          </BIcon>
        </BBtn>
        <template #content>
          <div class="save-text">
            {{ $t('general.revoke') }}
          </div>
        </template>
      </BTooltip>
    </template>
    <div class="history-card-body">
      <BPriorityPreview
        v-if="nextAction.priority !== 'not_set'"
        :priority="nextAction.priority"
      />
      <BEditableContentTextarea
        v-if="editable"
        v-model="content"
        class="card-item next-action-comment-area"
        :placeholder="$t('nextAction.content')"
        @check="updateNextActionContent($event)"
        @click="setTargetHeaderMoveButtonDisable(true)"
        @cancel="setTargetHeaderMoveButtonDisable(false)"
      />
      <div
        v-else
        class="card-item next-action-comment-area readonly"
      >
        {{ content }}
      </div>
      <BLayout
        v-if="content"
        justify-end
        class="mt-50"
      >
        <BCopyButton
          :text="content"
          :title="$t('nextAction.content')"
          is-detailed-name
        />
      </BLayout>
    </div>
  </LeadHistoryCard>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import errorHandler from '@/mixins/error_handler';
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  components: {
    LeadHistoryCard,
  },
  mixins: [errorHandler],
  props: {
    nextAction: Object,
  },
  emits: [
    'done-cancel',
  ],
  data () {
    return {
      content: this.nextAction.content,
    };
  },
  computed: {
    nextActionText () {
      return this.nextAction.name + this.$t(`general.done.text`);
    },
    deletable() {
      return this.nextAction.deletable;
    },
    editable() {
      return this.nextAction.editable;
    },
  },
  watch: {
    'nextAction.content' (newVal) {
      this.content = newVal;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      updateNextActionContentAction: 'updateNextActionContentAction',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
    }),
    ...mapWaitingActions('userUi', {
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
    }),
    setTargetHeaderMoveButtonDisable (status) {
      this.setTargetHeaderMoveButtonDisabledAction(status);
    },
    updateNextActionContent () {
      this.setTargetHeaderMoveButtonDisabledAction(false);
      this.updateNextActionContentAction({
        nextActionId: this.nextAction.id,
        callTargetId: this.nextAction.callTargetId,
        body: { content: this.content },
        errorHandlers: {
          422: this.defaultHandler,
        },
      }).then(() => {
        return this.getLeadHistoriesBackgroundAction({ callTargetId: this.nextAction.callTargetId });
      }).then(() => {
        return this.getCallTargetsBackgroundAction({ callListId: this.$route.params.id });
      });
    },
    handleDoneCancel () {
      const nextAction = this.$JSON.parse(this.$JSON.stringify(this.nextAction));
      this.$emit('done-cancel', nextAction);
    },
    getTimeWithDateAndTimeFilter (time) {
      return altText(formatShorterDateTime(time));
    },
  },
};
</script>

<style lang="scss" scoped>
  .nextaction-card {
    padding: $basespace-500 16px $basespace-500 80px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .created-at {
    display: inline-block;
    width: 120px;
  }

  .history-card-body {
    white-space: pre-wrap;
    word-wrap: break-word;

    .priority-preview {
      margin-left: calc(#{$basespace-100} + 2px);
      margin-bottom: $basespace-50;
    }
  }

  .next-action-comment-area.readonly {
    padding: 4px 8px;
  }
</style>
